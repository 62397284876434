import React from "react";
import { Container, Row, Col } from "react-bootstrap";

function Logo(props) {
  return (
    <div className="personal_details">
      <h2>Logo Brief</h2>
      <Row className="pb-2 pt-2">
        <Col>
          <label for="logo_name">
            Company/Product/Service name to be used in the logo
          </label>
          <input
            id="logo_name"
            name="logo_name"
            type="text"
            onChange={props.handlechange}
            required
          />
        </Col>
      </Row>
      <Row className="pb-2 pt-2">
        <Col>
          <label for="tag_line">Tag line (if any)</label>
          <input
            id="tag_line"
            name="tag_line"
            type="text"
            // placeholder="yourname@email.com"
            onChange={props.handlechange}
          />
        </Col>
      </Row>
      <Row className="pb-2 pt-2">
        <Col>
          <label for="about_description">
            Tell us a little more about your product/service
          </label>
          <input
            id="about_description"
            name="about_description"
            type="text"
            placeholder="i.e. ( consumer, Student, Business )"
            onChange={props.handlechange}
          />
        </Col>
      </Row>
      <Row className="pb-2 pt-2">
        <Col>
          <label for="target_audiance">
            Describe the audience you're targeting (age, gender, interests,
            income, geography, etc.)
          </label>
          <input
            name="target_audiance"
            id="target_audiance"
            type="text"
            // placeholder="Your Website"
            onChange={props.handlechange}
          />
        </Col>
      </Row>
      <Row className="pb-2 pt-2">
        <Col>
          <label for="compitators_logo">
            How do you want to be known in your industry and when compared to
            your compettors?
          </label>
          <input
            id="compitators_logo"
            name="compitators_logo"
            type="text"
            // placeholder="Complete Address"
            onChange={props.handlechange}
          />
        </Col>
      </Row>
      <Row className="pb-2 pt-2">
        <Col>
          <label for="any_idea_have">
            Do you have an idea of what you want? If so, describe in as much or
            as little detail as you'd like...
          </label>
          <input
            id="any_idea_have"
            name="any_idea_have"
            type="text"
            placeholder="i.e. Corporate, Trendy, Hi-tech, Fun & Friendly"
            onChange={props.handlechange}
          />
        </Col>
      </Row>
      <Row className="pb-2 pt-2">
        <Col>
          <label for="color_fonts">
            Any specific colors or fonts would you like to be incorporated into
            the artwork?
          </label>
          <input
            id="color_fonts"
            name="color_fonts"
            type="text"
            // placeholder="i.e. Corporate, Trendy, Hi-tech, Fun & Friendly"
            onChange={props.handlechange}
          />
        </Col>
      </Row>
      <Row className="pb-2 pt-2">
        <Col>
          <label for="time_frame">
            Are you currently working with any time constraints? Ideally, how
            soon would you like to have your new logo completed?
          </label>
          <input
            id="time_frame"
            name="time_frame"
            type="text"
            placeholder="i.e. Home, About US, Contact US"
            onChange={props.handlechange}
          />
        </Col>
      </Row>
      <Row className="pb-2 pt-2">
        <Col>
          <Row>
            <Col className="p-0 m-0" sm={1}>
              <input
                id="accept_terms_and_condition"
                type="checkbox"
                name="accept_terms_and_condition"
                onChange={props.handlechange}
                required
              />
            </Col>
            <Col className="p-0 m-0">
              <label for="accept_terms_and_condition">
                I agree to{" "}
                <a
                  href="https://www.knotsdigital.com/privacy-policy"
                  target="_blank"
                >
                  Terms of Services
                </a>{" "}
                and
                <a
                  href="https://www.knotsdigital.com/privacy-policy"
                  target="_blank"
                >
                  {" "}
                  Privacy Policy
                </a>
                . *
              </label>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="justify-content-start">
        <Col className="col-6" lg={4}>
          <button className="brief_btn text-black" onClick={props.backprevious}>
            Previous
          </button>
        </Col>
        <Col className="col-6" lg={4}>
          <button className="brief_btn text-black" type="submit">
            Submit
          </button>
        </Col>
      </Row>
    </div>
  );
}

export default Logo;
