import react, { Component } from "react";
import img17 from "../../assets/images/Packages/Price Icon.png";
import img18 from "../../assets/images/Packages/cancel.png";
var num = 10;
const Information = {
  Sheet1: [
    {
      Overview: "Logo Design Concepts",
      Basic: "1 Logo Design",
      "Start Up": "2 Logo Desin",
      Professional: "5 Logo Design",
      Business: "Unlimited",
      Elite: "Unlimited",
      Gold: "Unlimited",
    },
    {
      Overview: "Design Artist",
      Basic: "One",
      "Start Up": "Two",
      Professional: "Three",
      Business: "Five",
      Elite: "Eight",
      Gold: "Eight",
    },
    {
      Overview: "Page Custom Website",
      Basic: "",
      "Start Up": "",
      Professional: "",
      Business: "",
      Elite: "",
      Gold: "",
    },
    {
      Overview: "Revisions",
      Basic: "Two",
      "Start Up": "Four",
      Professional: "Unlimited",
      Business: "Unlimited",
      Elite: "Unlimited",
      Gold: "Unlimited",
    },
    {
      Overview: "Stationary Design Sets",
      Basic: <img src={img18} alt="" width="12px" />,
      "Start Up": <img src={img18} alt="" width="12px" />,
      Professional: <img src={img17} alt="" />,
      Business: <img src={img17} alt="" />,
      Elite: <img src={img17} alt="" />,
      Gold: <img src={img17} alt="" />,
    },
    {
      Overview: "MS Word Letterhead",
      Basic: <img src={img18} alt="" width="12px" />,
      "Start Up": <img src={img18} alt="" width="12px" />,
      Professional: <img src={img17} alt="" />,
      Business: <img src={img17} alt="" />,
      Elite: <img src={img17} alt="" />,
      Gold: <img src={img17} alt="" />,
    },
    {
      Overview: "Email Signature",
      Basic: <img src={img18} alt="" width="12px" />,
      "Start Up": <img src={img18} alt="" width="12px" />,
      Professional: <img src={img18} alt="" width="12px" />,
      Business: "yes",
      Elite: "yes",
      Gold: <img src={img17} alt="" />,
    },
    {
      Overview: "Content Management System",
      Basic: <img src={img18} alt="" width="12px" />,
      "Start Up": <img src={img18} alt="" width="12px" />,
      Professional: <img src={img18} alt="" width="12px" />,
      Business: <img src={img18} alt="" width="12px" />,
      Elite: <img src={img18} alt="" width="12px" />,
      Gold: <img src={img17} alt="" />,
    },
    {
      Overview: "Stock Photos",
      Basic: <img src={img18} alt="" width="12px" />,
      "Start Up": <img src={img18} alt="" width="12px" />,
      Professional: <img src={img18} alt="" width="12px" />,
      Business: <img src={img18} alt="" width="12px" />,
      Elite: <img src={img18} alt="" width="12px" />,
      Gold: "Two",
    },
    {
      Overview: "Banner Designs",
      Basic: <img src={img18} alt="" width="12px" />,
      "Start Up": <img src={img18} alt="" width="12px" />,
      Professional: <img src={img18} alt="" width="12px" />,
      Business: <img src={img18} alt="" width="12px" />,
      Elite: <img src={img18} alt="" width="12px" />,
      Gold: "Two",
    },
    {
      Overview: "jQuery Slider",
      Basic: <img src={img18} alt="" width="12px" />,
      "Start Up": <img src={img18} alt="" width="12px" />,
      Professional: <img src={img18} alt="" width="12px" />,
      Business: <img src={img18} alt="" width="12px" />,
      Elite: <img src={img18} alt="" width="12px" />,
      Gold: <img src={img17} alt="" />,
    },
    {
      Overview: "Files Format",
      Basic: "(JBG)",
      "Start Up": "All Formats",
      Professional: "All Formats",
      Business: "All Formats",
      Elite: "All Formats",
      Gold: "All Formats",
    },
    {
      Overview: "Ownership Rights",
      Basic: <img src={img17} alt="" />,
      "Start Up": <img src={img17} alt="" />,
      Professional: <img src={img17} alt="" />,
      Business: <img src={img17} alt="" />,
      Elite: <img src={img17} alt="" />,
      Gold: <img src={img17} alt="" />,
    },
    {
      Overview: "Satisfaction Guarantee",
      Basic: <img src={img17} alt="" />,
      "Start Up": <img src={img17} alt="" />,
      Professional: <img src={img17} alt="" />,
      Business: <img src={img17} alt="" />,
      Elite: <img src={img17} alt="" />,
      Gold: <img src={img17} alt="" />,
    },
    {
      Overview: "Unique Design Guarantee",
      Basic: <img src={img17} alt="" />,
      "Start Up": <img src={img17} alt="" />,
      Professional: <img src={img17} alt="" />,
      Business: <img src={img17} alt="" />,
      Elite: <img src={img17} alt="" />,
      Gold: <img src={img17} alt="" />,
    },
    {
      Overview: "Money Back Guarantee",
      Basic: <img src={img17} alt="" />,
      "Start Up": <img src={img17} alt="" />,
      Professional: <img src={img17} alt="" />,
      Business: <img src={img17} alt="" />,
      Elite: <img src={img17} alt="" />,
      Gold: <img src={img17} alt="" />,
    },
  ],
  LogoPackages: [
    {
      data: {
        id: 1,
        Package: {
          Cat: "Basic Logo Package",
          price: "35",
          description:
            "Suitable for potential super-startups and brand revamps for companies.",
          list: [
            "3 Custom Logo Design Concepts",
            "1 Dedicated Designer",
            "1 Revisions",
            "2 hours TAT",
            "100% Unique Design Guarantee",
            "100% Satisfaction Guarantee",
            "100% Money Back Guarantee *",
            "100% Ownership Rights",
          ],
        },
      },
      type: "Logo",
    },
    {
      data: {
        id: 2,
        Package: {
          Cat: "Start Up Logo Package",
          price: "65",
          description:
            "Suitable for potential super-startups and brand revamps for companies.",
          list: [
            "4 Custom Logo Design Concepts",
            "By 2 Designers",
            "Unlimited Revisions",
            "24 to 48 hours TAT",
            "100% Satisfaction Guarantee",
            "100% Unique Design Guarantee",
            "100% Money Back Guarantee*",
            "100% Ownership Rights",
          ],
        },
      },
      type: "Logo",
    },
    {
      data: {
        id: 3,
        Package: {
          Cat: "Professional Logo Package",
          price: "99",
          description:
            "Suitable for potential super-startups and brand revamps for companies.",
          list: [
            "Unlimited Logo Design Concepts",
            "By 4 Industry Based Designers",
            "UNLIMITED Revisions",
            "FREE MS Electronic Letterhead",
            "FREE Custom Stationery Design",
            "(Letterhead, Business Card, Envelope)",
            "24 to 48 hours TAT",
            "FREE File Formats (EPS, Ai, GIF, JPEG, PSD)",
            "100% Satisfaction Guarantee",
            "100% Unique Design Guarantee",
            "100% Money Back Guarantee",
            "100% Ownership Rights",
          ],
        },
      },
      type: "Logo",
    },
    {
      data: {
        id: 4,
        Package: {
          Cat: "Elite Logo Package",
          price: "149",
          description:
            "Suitable for potential super-startups and brand revamps for companies.",
          list: [
            "UNLIMITED Logo Design Concepts",
            "By 8 Award Winning Designers",
            "Free Icon Design",
            "FREE Custom Stationery Design",
            "(Letterhead, Business Card, Envelope, Invoice)",
            "Double Side Flyer (OR) Bi-Fold Brochure",
            "FREE MS Electronic Letterhead",
            "Email Signature Design",
            "UNLIMITED Revisions",
            "48 to 72 hours TAT",
            "100% Satisfaction Guarantee",
            "100% Unique Design Guarantee",
            "100% Money Back Guarantee",
            "100% Ownership Rights",
          ],
        },
      },
      type: "Logo",
    },
    {
      data: {
        id: 5,
        Package: {
          Cat: "Gold Logo Package",
          price: "249",
          description:
            "Suitable for potential super-startups and brand revamps for companies.",
          list: [
            "UNLIMITED Logo Design Concepts",
            "By 8 Award Winning Designers",
            "Icon Design",
            "UNLIMITED Revisions",
            "2 Stationary Design Sets (Business Card, Letterhead, Envelope)",
            "FREE MS Word Letterhead",
            "Free Email Signature",
            "3 Page Custom Website",
            "2 Stock Photos",
            "2 Banner Designs",
            "jQuery Slider",
            "All Final Files Format (AI, PSD, EPS, PNG, GIF, JPG, PDF)",
            "100% Ownership Rights",
            "100% Satisfaction Guarantee",
            "100% Unique Design Guarantee",
            "100% Money Back Guarantee *",
          ],
        },
      },
      type: "Logo",
    },
  ],
  WebsitePackages: [
    {
      data: {
        id: 1,
        Package: {
          Cat: "Basic Website",
          price: "244",
          description:
            "Suitable for potential Basic Website and brand revamps for companies.",
          list: [
            "2 Stock Images",
            "3 Page Website",
            "1 jQuery Slider Banner",
            "Contact/Query Form",
            "Complete W3C CertifiedHTML",
            "48 to 72 hours TAT",
            "Complete Deployment",
            "100% Satisfaction Guarantee",
            "100% Unique Design Guarantee",
            "100% Money Back Guarantee *",
            "Mobile Responsive will be Additional $200*CMS will be Additional $250",
          ],
        },
      },
      type: "Website",
    },
    {
      data: {
        id: 2,
        Package: {
          Cat: "Startup Website Packages",
          price: "394",
          description:
            "Suitable for potential Startup Website and brand revamps for companies.",
          list: [
            "3 Banner Design",
            "1 jQuery Slider Banner",
            "FREE Google Friendly Sitemap",
            "Complete W3C Certified HTML",
            "48 to 72 hours TAT",
            "100% Satisfaction Guarantee",
            "100% Unique Design Guarantee",
            "100% Money Back Guarantee *",
            "Mobile Responsive will be Additional $200*",
            "CMS will be Additional $250*",
          ],
        },
      },
      type: "Website",
    },
    {
      data: {
        id: 3,
        Package: {
          Cat: "Professional Website Packages",
          price: "844",
          description:
            "Suitable for potential Professional Websites and brand revamps for companies.",
          list: [
            "10 Unique Pages Website",
            "CMS / Admin Panel Support",
            "8 Stock images",
            "5 Banner Designs",
            "1 jQuery Slider Banner",
            "FREE Google Friendly Sitemap",
            "Complete W3C Certified HTML",
            "48 to 72 hours TAT",
            "Complete Deployment",
            "100% Satisfaction Guarantee",
            "100% Unique Design Guarantee",
            "100% Money Back Guarantee *",
            "Mobile Responsive will be Additional $200*",
          ],
        },
      },
      type: "Website",
    },
    {
      data: {
        id: 4,
        Package: {
          Cat: "Elite Website Packages",
          price: "1,494",
          description:
            "Suitable for potential Elite Website and brand revamps for companies.",
          list: [
            "Upto 15 Unique Pages Website",
            "Conceptual and Dynamic Website",
            "Mobile Responsive",
            "Online Reservation/Appointment Tool (Optional)",
            "Online Payment Integration (Optional)",
            "Custom Forms",
            "Lead Capturing Forms (Optional)",
            "Striking Hover Effects",
            "Newsletter Subscription (Optional)",
            "Newsfeed Integration",
            "Social Media Integration",
            "Search Engine Submission",
            "5 Stock Photos",
            "3 Unique Banner Design",
            "1 jQuery Slider Banner",
            "Complete W3C Certified HTML",
            "48 to 72 hours TAT",
            "Complete Deployment",
            "100% Satisfaction Guarantee",
            "100% Unique Design Guarantee",
            "100% Money Back Guarantee *",
          ],
        },
      },
      type: "Website",
    },
    {
      data: {
        id: 5,
        Package: {
          Cat: "Corporate Website Packages",
          price: "2,394",
          description:
            "Suitable for potential Corporate Website and brand revamps for companies.",
          list: [
            "15 to 20 Pages Website",
            "Custom Made",
            "Interactive",
            "Dynamic & High End Design",
            "Custom WP (or) Custom PHP Development",
            "1 jQuery Slider Banner",
            "Up to 10 Custom Made Banner Designs",
            "10 Stock Images",
            "Unlimited Revisions",
            "Special Hoover Effects",
            "Content Management System (CMS)",
            "Online Appointment/Scheduling/Online Ordering Integration (Optional)",
            "Online Payment Integration (Optional)",
            "Multi Lingual (Optional)",
            "Custom Dynamic Forms (Optional)",
            "Signup Area (For Newsletters",
            "Offers etc.)",
            "Search Bar",
            "Live Feeds of Social Networks integration (Optional)",
            "Mobile Responsive",
            "FREE 5 Years Domain Name",
            "Free Google Friendly Sitemap",
            "Search Engine Submission",
            "Complete W3C Certified HTML",
            "Industry Specified Team of Expert Designers and Developers",
            "Complete Deployment",
            "Dedicated Accounts Manager",
            "100% Ownership Rights",
            "100% Satisfaction Guarantee",
            "100% Unique Design Guarantee",
            "100% Money Back Guarantee *",
          ],
        },
      },
      type: "Website",
    },
    {
      data: {
        id: 6,
        Package: {
          Cat: "Business Website Packages",
          price: "2,999",
          description:
            "Suitable for potential Business Website and brand revamps for companies.",
          list: [
            "15 Seconds 2D Explainer Video",
            "Voice - Over & Sound Effects",
            "Professional Script Writing",
            "Storyboard",
            "SEO Meta Tags",
            "15 to 20 Pages Website",
            "Custom Made",
            "Interactive",
            "Dynamic & High End Design",
            "Custom WP (or) Custom PHP Development",
            "1 jQuery Slider Banner",
            "Up to 10 Custom Made Banner Designs",
            "10 Stock Images",
            "Unlimited Revisions",
            "Special Hoover Effects",
            "Content Management System (CMS)",
            "Online Appointment/Scheduling/Online Ordering Integration (Optional)",
            "Online Payment Integration (Optional)",
            "Multi Lingual (Optional)",
            "Custom Dynamic Forms (Optional)",
            "Signup Area (For Newsletters",
            "Offers etc.)",
            "Search Bar",
            "Live Feeds of Social Networks integration (Optional)",
            "Mobile Responsive",
            "FREE 5 Years Domain Name",
            "Free Google Friendly Sitemap",
            "Search Engine Submission",
            "Complete W3C Certified HTML",
            "Industry Specified Team of Expert Designers and Developers",
            "Complete Deployment",
            "Dedicated Accounts Manager",
            "100% Ownership Rights",
            "100% Satisfaction Guarantee",
            "100% Unique Design Guarantee",
            "100% Money Back Guarantee *",
          ],
        },
      },
      type: "Website",
    },
  ],
  EcommercePackages: [
    {
      data: {
        id: 1,
        Package: {
          Cat: "Beginners E-Commerce Package",
          price: "789",
          list: [
            "Up-to 10 Unique Pages Website",
            "Up-to 100 Products",
            "Content Management System (CMS)",
            "Mobile Responsive",
            "Mini Shopping Cart Integration",
            "Payment Module Integration",
            "Free Google Friendly Sitemap",
            "Complete W3C Certified HTML",
            "Complete Deployment",
            "Dedicated Accounts Manager",
            "100% Ownership Rights",
            "100% Satisfaction Guarantee",
            "100% Unique Design Guarantee",
            "100% Money Back Guarantee",
          ],
        },
      },
      type: "Ecommerce",
    },
    {
      data: {
        id: 2,
        Package: {
          Cat: "Professional E-Commerce Package",
          price: "1,589",
          list: [
            "Up-to 15 Unique Pages Website",
            "Up-to 500 Products",
            "Conceptual and Dynamic Website",
            "Easy Product Search",
            "Product Reviews",
            "Content Management System (CMS)",
            "Mobile Responsive",
            "Full Shopping Cart Integration",
            "Payment Module Integration",
            "Sales & Inventory Management",
            "Jquery Slider",
            "Free Google Friendly Sitemap",
            "Custom Email Addresses",
            "Complete W3C Certified HTML",
            "Complete Deployment",
            "Dedicated Accounts Manager",
            "100% Ownership Rights",
            "100% Satisfaction Guarantee",
            "100% Unique Design Guarantee",
            "100% Money Back Guarantee",
          ],
        },
      },
      type: "Ecommerce",
    },
    {
      data: {
        id: 3,
        Package: {
          Cat: "Elite E-Commerce Package",
          price: "3,999",
          list: [
            "Unlimited Pages Website",
            "E-Commerce Store Design",
            "Product Detail Page Design",
            "Unlimited Products",
            "Unlimited Categories",
            "Product Rating & Reviews",
            "Unique Banner Slider",
            "Featured Products Showcase",
            "Full Shopping Cart Integration",
            "Multi-currency Support",
            "Online Payment Module Setup",
            "Facebook Shop Integration",
            "Sales & Inventory Management",
            "Easy Product Search",
            "Payment Gateway Integration",
            "Content Management System/CMS",
            "Customer Log-in Area",
            "Mobile Responsive",
            "Social Media Plugins Integration",
            "Tell a Friend Feature",
            "Social Media Pages",
            "Value Added Services",
            "FREE Custom Logo Design",
            "FREE Icon Design",
            "FREE Print Media",
            "FREE Stationary Design (Business Card",
            "Letterhead & Envelope Design)",
            "FREE Invoice Design",
            "FREE Bi-Fold Brochure (OR) 2-Sided Flyer Design",
            "FREE Product Catalog Design",
            "FREE Sign age Design (OR) Label Design",
            "FREE T-Shirt Design (OR) Car Wrap Design",
            "FREE Email Signature Design",
            "FREE Facebook",
            "Twitter",
            "YouTube",
            "Google+ & Pinterest Page Designs",
            "Dedicated Account Manager",
            "Unlimited Revisions",
            "All Final File Formats",
            "100% Ownership Rights",
            "100% Satisfaction Guarantee",
            "100% Unique Design Guarantee",
            "100% Money Back Guarantee",
          ],
        },
      },
      type: "Ecommerce",
    },
  ],
  Smmpackages: [
    {
      data: {
        id: 1,
        Package: {
          Cat: "Basic",
          price: "250",
          description:
            "Suitable for potential super-startups and brand revamps for companies.",
          list: [
            "3 Social Media Channels (Facebook / Twitter / Instagram)",
            "Social media account setup",
            "1 Post Design",
            "1 Cover Banner",
          ],
        },
      },
      type: "SMM",
    },
    {
      data: {
        id: 2,
        Package: {
          Cat: "Startup",
          price: "600",
          description:
            "Suitable for potential super-startups and brand revamps for companies.",
          list: [
            "2 Social Media Channels (Facebook / Instagram)",
            "Social media account setup",
            "Complete Account Management",
            "3 Posts per week (per network)",
            "Custom Editorial calendar (Review before publishing)",
            "Dedicated account manager",
            "Dedicated Budget For Paid Advertising",
          ],
        },
      },
      type: "SMM",
    },
    {
      data: {
        id: 3,
        Package: {
          Cat: "Plus",
          price: "1200",
          description:
            "Suitable for potential super-startups and brand revamps for companies.",
          list: [
            "4 Social Media Channels (Facebook / Twitter / Pinterest / Instagram)",
            "Social media account setup",
            "Complete Account Management 3 Posts per week (per network)",
            "Custom Editorial calendar (Review before publishing)",
            "Call to Action Integration",
            "End of Term Report",
            "Dedicated Account Manager",
            "Dedicated Budget For Paid Advertising",
          ],
        },
      },
      type: "SMM",
    },
  ],
  SEOPackages: [
    {
      data: {
        id: 1,
        Package: {
          Cat: "Basic SEO Package",
          price: "599",
          description:
            "Suitable for potential super-startups and brand revamps for companies.",
          list: [
            "Prior Analysis",
            "Business Analysis",
            "Consumer Analysis",
            "Competitor Analysis",
            "15 Selected Keywords Targeting",
            "Webpage Optimization",
            "15 Pages Keyword Targeted",
            "Meta Tags Creation",
            "Keyword Optimization",
            "Image Optimization",
            "Inclusion Of Anchors Tracking & Analysis",
            "Google Analytics Installation",
            "Google Webmaster Installation",
            "Call To Action Plan",
            "Creation Of Sitemaps Reporting",
            "Monthly Reporting",
            "Recommendation",
            "Email Supportn",
            "Phone Support Off Page Optimization",
            "Social Bookmarking",
            "Slide Share Marketing",
            "Forums/FAQâ€™s",
            "Link Building",
            "Directory Submission",
            "Local Business Listings",
          ],
        },
      },
      type: "SEO",
    },
    {
      data: {
        id: 2,
        Package: {
          Cat: "Start Up SEO Package",
          price: "999",
          description:
            "Suitable for potential super-startups and brand revamps for companies.",
          list: [
            "Prior Analysis",
            "Business Analysis",
            "Consumer Analysis",
            "Competitor Analysis",
            "30 Selected Keywords Targeting",
            "30 Pages Keywords Targeting",
            "Webpage Optimization",
            "Meta Tags Creation",
            "Image Optimization",
            "Inclusion Of Anchors Tracking & Analysis",
            "Google Analytics Installation",
            "Google Webmaster Installation",
            "Call To Action Plan",
            "Creation Of Sitemaps Reporting",
            "Monthly Reporting",
            "Recommendation",
            "Email Supportn",
            "Phone Support Off Page Optimization",
            "Social Bookmarking",
            "Slide Share Marketing",
            "Forums/FAQâ€™s",
            "Link Building",
            "Directory Submission",
            "Local Business Listings",
          ],
        },
      },
      type: "SEO",
    },
    {
      data: {
        id: 3,
        Package: {
          Cat: "Professional SEO Package",
          price: "1499",
          description:
            "Suitable for potential super-startups and brand revamps for companies.",
          list: [
            "Prior Analysis",
            "Business Analysis",
            "Consumer Analysis",
            "Competitor Analysis",
            "70 Selected Keywords Targeting",
            "50 Pages Keywords Targeting",
            "Webpage Optimization",
            "Meta Tags Creation",
            "Image Optimization",
            "Inclusion Of Anchors Indexing Modifications",
            "Tracking & Analysis",
            "Google Analytics Installation",
            "Google Webmaster Installation",
            "Call To Action Plan",
            "Creation Of Sitemaps",
            "Reporting",
            "Monthly Reporting",
            "Recommendation",
            "Email Support",
            "Phone Support Off Page Optimization",
            "Social Bookmarking",
            "Slide Share Marketing",
            "Forums/FAQâ€™s",
            "Link Building",
            "Directory Submission",
            "Local Business Listings",
            "Blog Content Creation",
            "Local SEO",
          ],
        },
      },
      type: "SEO",
    },
  ],
  BrandingPackages: [
    {
      data: {
        id: 1,
        Package: {
          Cat: "Basic Branding Package",
          price: "350",
          description:
            "Suitable for potential super-startups and brand revamps for companies.",
          list: [
            "1 Stationery Design Set Concepts",
            "1 Flyer Design",
            "1 Banner Design Concept (any size)",
            "1 Brochure Design (Bi-fold/Tri-fold)",
            "2 Stock Photos",
            "T-Shirt Design (2 Concepts)",
            "Menu Card Design (2 Concepts)",
            "Free Social Media Banners (any 3)",
            "Free Email Signature",
            "Free Word Doc for Letterhead",
            "3 Revisions",
            "Turn Around 48-72 Hours",
            "Print Ready Formats",
          ],
        },
      },
      type: "Branding",
    },
    {
      data: {
        id: 2,
        Package: {
          Cat: "Start Up Branding Package",
          price: "500",
          description:
            "Suitable for potential super-startups and brand revamps for companies.",
          list: [
            "2 Stationery Design Set Concepts",
            "2 Flyer Design",
            "2 Banner Design Concept (any size)",
            "2 Brochure Design concept (Bi-fold/Tri-fold)",
            "4 Stock Photos",
            "T-Shirt Design (3 Concepts)",
            "Menu Card Design (3 Concepts)",
            "Free Social Media Banners (any 5)",
            "Free Email Signature",
            "Free Word Doc for Letterhead",
            "4 Revisions",
            "Turn Around 48-72 Hours",
            "Print Ready Formats",
          ],
        },
      },
      type: "Branding",
    },
    {
      data: {
        id: 3,
        Package: {
          Cat: "Professional Branding Package",
          price: "650",
          description:
            "Suitable for potential super-startups and brand revamps for companies.",
          list: [
            "4 Stationery Design Set Concepts",
            "3 Flyer Design",
            "3 Banner Design Concept (any size)",
            "4 Brochure Design concept (Bi-fold/Tri-fold)",
            "5 Stock Photos",
            "T-Shirt Design (4 Concepts)",
            "Menu Card Design (4 Concepts)",
            "Free Social Media Banners (any 5)",
            "Free Email Signature",
            "Free Word Doc for Letterhead",
            "Unlimited Revisions",
            "Turn Around 48-72 Hours",
            "Print Ready Formats",
          ],
        },
      },
      type: "Branding",
    },
  ],
  AnimationPackages: [
    {
      data: {
        id: 1,
        Package: {
          Cat: "Basic Animation Package",
          price: "999",
          description:
            "Suitable for potential super-startups and brand revamps for companies.",
          list: [
            "30 Sec. Duration",
            "Script Creation",
            "Storyboard Conceptualization",
            "Storyboard Designing",
            "Voice-over",
            "Animation & Sound Effect",
          ],
        },
      },
      type: "Animation",
    },
    {
      data: {
        id: 2,
        Package: {
          Cat: "Start Up Animation Package",
          price: "1899",
          description:
            "Suitable for potential super-startups and brand revamps for companies.",
          list: [
            "60 Sec. Duration",
            "Script Creation",
            "Storyboard Conceptualization",
            "Characters Modelling",
            "Voice-over",
            "Animation & Sound Effect",
          ],
        },
      },
      type: "Animation",
    },
    {
      data: {
        id: 3,
        Package: {
          Cat: "Professional Animation Package",
          price: "2499",
          description:
            "Suitable for potential super-startups and brand revamps for companies.",
          list: [
            "90 Sec. Duration",
            "Script Creation",
            "Storyboard Conceptualization",
            "Voice-over",
            "Animation & Sound Effect",
          ],
        },
      },
      type: "Animation",
    },
    {
      data: {
        id: 4,
        Package: {
          Cat: "Elite Animation Package",
          price: "3199",
          description:
            "Suitable for potential super-startups and brand revamps for companies.",
          list: [
            "120 Sec. Duration",
            "Script Creation",
            "Storyboard Conceptualization",
            "Voice-over",
            "Animation & Sound Effect",
          ],
        },
      },
      type: "Animation",
    },
  ],
};

// console.log('ALL DATA',data)
export default Information;

export const CaseStudyInormation = {
  sheet1: [
    {
      id: 1,
      projecttitile: "Original stitch",
      banner_pera:
        "Original Stitch is a custom clothing company that offers a wide range of shirt projects for customers. These projects allow customers to create their own unique shirts by selecting from various options such as fabric, collars, cuffs, buttons, and more.",
      banner_img: require("../../assets/images/CaseStudy/1/P-1.webp"),
      the_object:
        "Original stitch shirts project is to design and produce high-quality, custom-fit shirts that cater to the individual needs of each customer. The project aims to provide a seamless online ordering experience that combines the latest technology with traditional tailoring techniques. The goal is to deliver a unique, personalized product that is both comfortable and stylish, allowing customers to express their individual style and feel confident in their appearance. Ultimately, the objective of this project is to revolutionize the way people buy shirts, creating a new standard for quality and style in the world of fashion.",
      unique_problem: [
        {
          title: "Visually Differentiation",
          discription:
            "Original Stitch shirts requires a focus on the unique features and benefits of our product offerings. This can be accomplished through the use of high-quality product photography, eye-catching product descriptions, and bold and distinctive branding elements. Additionally, we can differentiate ourselves by offering unique customization options and a wide range of sizes and styles, which can help to set our store apart from others in the market. By focusing on these key areas, we can create a visually appealing and memorable online shopping experience that stands out from the competition.",
        },
        {
          title: "Maintaining Lightening Speed",
          discription:
            "The Original Stitch shirts website is optimized for speed to provide the best possible user experience. The website uses fast and efficient coding, reducing the load time of pages and improving the overall speed of the website. The website also uses optimized images, reducing the size of images while maintaining high quality, which helps to further improve speed. The website also uses caching, which helps to reduce the time it takes to load pages and improve the overall speed of the website. The website also has a responsive design, which allows it to adjust to different screen sizes, improving the overall user experience and reducing load times. Overall, the Original Stitch shirts website is designed to be fast and efficient, providing users with a smooth and seamless experience.",
        },
      ],
      whatwedid: [
        { list: "Digital Strategy" },
        { list: "UI/UX Design" },
        { list: "Design and Development" },
        { list: "Web Development" },
        { list: "Content Strategy" },
        { list: "Copywriting" },
      ],
      platform: [{ list: "WordPress" }, { list: "SCSS" }, { list: "PHP" }, {list: "BootStrap"}],
      result: [{ overall: 68 }, { bounce: 27 }, { value: 10 }],
      home: [
        {
          text: "The homepage includes several pops of color that highlights the products as well as the branding of Luseta Beauty. The product images on the homepage scrolls to show more product images and shows a mix of lifestyle and product images.",
        },
        { img: require("../../assets/images/CaseStudy/1/P-2.png") },
      ],
      section1: [
        { heading: "Website Navigation" },
        {
          pera: "The site navigation is laid out in a clean and concise manner making it easy to find each product page based upon best selling products, value sets, collections, etc.",
        },
        { img: require("../../assets/images/CaseStudy/1/C-1.png") },
      ],
      section2: [
        { heading: "Add To Cart Experience" },
        {
          pera: "The side cart allows users to to continue to shop while also being able to to view their shopping cart. The free shipping experience is an added bonus that entices customers to want to shop more!",
        },
        { img: require("../../assets/images/CaseStudy/1/C-2.png") },
      ],
      section3: [
        { heading: "Shirt Customization" },
        {
          pera: "Original Stitch is a leading website for customizing shirts that offers a unique and personalized experience. Their website offers a wide range of fabric options and a comprehensive design tool for customers to create their own unique shirts. The customization process is simple and easy to follow, and customers can select from a range of styles, collars, cuffs, and buttons. The website provides detailed size guides and offers the option to upload photos of themselves to ensure the perfect fit. All shirts are made to order, ensuring that each one is as unique as the person wearing it. With fast and free shipping, Original Stitch is the perfect place to find the perfect custom-made shirt.",
        },
        { img: require("../../assets/images/CaseStudy/1/C-3.png") },
      ],
      collection: [
        {
          text: "The collection pages can be found via the navigation or each through the Ingredient Slider. These pages are set up to highlight key ingredients or traits of a collection of products. Again creating a user experience that allows for customers to find products that meet their needs.",
        },
        { img: require("../../assets/images/CaseStudy/1/P-3.png") },
      ],
    },
    {
      id: 2,
      projecttitile: "Magda Butrym",
      banner_pera:
        "Magda Butrym is a premium fashion brand that offers a unique and sophisticated shopping experience through its website. The website showcases the brand's latest collections, including its iconic pieces and luxurious clothing and accessories. ",
      banner_img: require("../../assets/images/CaseStudy/2/P-1.webp"),
      the_object:
        "Magda Butrym clothing website project is to create an online platform that showcases the brand's luxurious and sophisticated collections, while providing customers with a seamless and premium shopping experience. The website will feature an online store where customers can purchase the latest collections, as well as a blog and lookbook that provide inspiration and style tips. The website will be designed with a focus on user experience, ensuring that customers can easily find the information they need and make purchases with ease. The objective is to create a platform that reflects the brand's sophisticated and elegant image and provides customers with a premium shopping experience, resulting in increased customer satisfaction and brand loyalty. The website will be optimized for fast loading times, security, and user-friendliness, ensuring a positive and memorable shopping experience for customers.",
      unique_problem: [
        {
          title: "Visually Differentiation",
          discription:
            "Magda Butrym's clothing website visually differentiates itself through its luxurious and sophisticated design. The website features a minimalist color palette of black, white, and gold, which creates a sense of elegance and refinement. The use of large, high-quality images and video showcases the brand's clothing and accessories in the best possible light, making it easy for customers to see the details and quality of each piece. The website's layout is clean and modern, with a focus on presenting the clothing and accessories in a visually appealing way. The use of typography and graphics is carefully considered, adding to the overall sophisticated look and feel of the website. The visual differentiation of Magda Butrym's clothing website sets it apart from its competitors and appeals to customers who are looking for a premium and high-quality fashion experience.",
        },
        {
          title: "Maintaining Lightening Speed",
          discription:
            "Maintaining the lightning speed of the Magda Butrym clothing website is crucial to ensuring a seamless and efficient browsing experience for customers. The website is regularly optimized and updated to ensure that it runs smoothly and quickly. The use of optimized images, minimalistic design, and efficient coding helps to maintain the lightning speed of the website. Regular performance monitoring and load testing is conducted to identify and fix any issues that may impact the speed and performance of the website. The website's hosting infrastructure is also regularly reviewed to ensure that it is robust and reliable, providing customers with a fast and efficient browsing experience. Maintaining the lightning speed of the website is essential to providing customers with a positive and enjoyable shopping experience, resulting in increased customer satisfaction and brand loyalty.",
        },
      ],
      whatwedid: [
        { list: "Digital Strategy" },
        { list: "UI/UX Design" },
        { list: "Design and Development" },
        { list: "Web Development" },
        { list: "Content Strategy" },
        { list: "Copywriting" },
      ],
      platform: [{ list: "React" }, { list: "NodeJs" }, { list: "NextJs" }, {list: 'SCSS'}],
      result: [{ overall: 68 }, { bounce: 27 }, { value: 10 }],
      home: [
        {
          text: "The homepage includes several pops of color that highlights the products as well as the branding of Luseta Beauty. The product images on the homepage scrolls to show more product images and shows a mix of lifestyle and product images.",
        },
        { img: require("../../assets/images/CaseStudy/2/P-2.png") },
      ],
      section1: [
        { heading: "Website Navigation" },
        {
          pera: "The site navigation is laid out in a clean and concise manner making it easy to find each product page based upon best selling products, value sets, collections, etc.",
        },
        { img: require("../../assets/images/CaseStudy/2/C-1.png") },
      ],
      section2: [
        { heading: "Add To Cart Experience" },
        {
          pera: "The side cart allows users to to continue to shop while also being able to to view their shopping cart. The free shipping experience is an added bonus that entices customers to want to shop more!",
        },
        { img: require("../../assets/images/CaseStudy/2/C-2.png") },
      ],
      section3: [
        { heading: "Shirt Customization" },
        {
          pera: "Clients hire us based on our merit – they love us for our accessibility. We go to great lengths to be incredibly responsive to your needs so you never feel ignored and always feel taken care of.",
        },
        { img: require("../../assets/images/CaseStudy/2/C-3.png") },
      ],
      collection: [
        {
          text: "The collection pages can be found via the navigation or each through the Ingredient Slider. These pages are set up to highlight key ingredients or traits of a collection of products. Again creating a user experience that allows for customers to find products that meet their needs.",
        },
        { img: require("../../assets/images/CaseStudy/2/P-3.png") },
      ],
    },
    {
      id: 3,
      projecttitile: "Tana",
      banner_pera:
        "Tana OS is a cutting-edge technology company that offers a comprehensive and innovative operating system designed specifically for the Internet of Things (IoT) devices. The company's website provides an overview of its products and services, including information on the Tana OS operating system and its capabilities.",
      banner_img: require("../../assets/images/CaseStudy/3/P-1.webp"),
      the_object:
        "Tana OS website is to provide customers with a comprehensive understanding of the company's products and services, as well as to facilitate the purchase and implementation of Tana OS operating system for IoT devices. The website serves as an information hub for customers, providing them with access to technical information, resources, and support. The objective is to make it easy for customers to understand the capabilities and benefits of Tana OS and to provide them with the tools and resources they need to integrate Tana OS into their IoT devices. The website is designed to be user-friendly and intuitive, providing customers with a seamless browsing experience. The ultimate goal is to increase customer satisfaction and drive sales by making it easy for customers to purchase, integrate, and use Tana OS operating system in their IoT devices.",
      unique_problem: [
        {
          title: "Visually Differentiation",
          discription:
            "The Tana OS website visually differentiates itself through its clean, modern, and minimalist design. The website features a predominantly white background with blue accents, creating a professional and high-tech look and feel. The use of large, high-quality images and video showcases the Tana OS operating system and its capabilities, making it easy for customers to understand the technology. The website's layout is organized and easy to navigate, with a focus on presenting the information in a clear and concise way. The use of typography and graphics is carefully considered, adding to the overall high-tech and professional look and feel of the website. The visual differentiation of the Tana OS website sets it apart from its competitors and appeals to customers who are looking for a reliable and innovative operating system for their IoT devices.",
        },
        {
          title: "Maintaining Lightening Speed",
          discription:
            "Maintaining the lightning speed of the Tana OS website is crucial to ensuring a seamless and efficient browsing experience for customers. The website is regularly optimized and updated to ensure that it runs smoothly and quickly. The use of optimized images, minimalistic design, and efficient coding helps to maintain the lightning speed of the website. Regular performance monitoring and load testing is conducted to identify and fix any issues that may impact the speed and performance of the website. The website's hosting infrastructure is also regularly reviewed to ensure that it is robust and reliable, providing customers with a fast and efficient browsing experience. Maintaining the lightning speed of the website is essential to providing customers with a positive and enjoyable browsing experience, resulting in increased customer satisfaction and brand loyalty.",
        },
      ],
      whatwedid: [
        { list: "Digital Strategy" },
        { list: "UI/UX Design" },
        { list: "Design and Development" },
        { list: "Web Development" },
        { list: "Content Strategy" },
        { list: "Copywriting" },
      ],
      platform: [{ list: "React" }, { list: "Nodejs" }, { list: "NextJs" }, { list: "SCSS & CSS" }],
      result: [{ overall: 68 }, { bounce: 27 }, { value: 10 }],
      home: [
        {
          text: "The homepage includes several pops of color that highlights the products as well as the branding of Luseta Beauty. The product images on the homepage scrolls to show more product images and shows a mix of lifestyle and product images.",
        },
        { img: require("../../assets/images/CaseStudy/3/P-2.png") },
      ],
      section1: [
        { heading: "Website Navigation" },
        {
          pera: "The site navigation is laid out in a clean and concise manner making it easy to find each product page based upon best selling products, value sets, collections, etc.",
        },
        { img: require("../../assets/images/CaseStudy/3/C-1.png") },
      ],
      section2: [
        { heading: "Add To Cart Experience" },
        {
          pera: "The side cart allows users to to continue to shop while also being able to to view their shopping cart. The free shipping experience is an added bonus that entices customers to want to shop more!",
        },
        { img: require("../../assets/images/CaseStudy/3/C-2.png") },
      ],
      section3: [
        { heading: "Shirt Customization" },
        {
          pera: "Clients hire us based on our merit – they love us for our accessibility. We go to great lengths to be incredibly responsive to your needs so you never feel ignored and always feel taken care of.",
        },
        { img: require("../../assets/images/CaseStudy/3/C-3.png") },
      ],
      collection: [
        {
          text: "The collection pages can be found via the navigation or each through the Ingredient Slider. These pages are set up to highlight key ingredients or traits of a collection of products. Again creating a user experience that allows for customers to find products that meet their needs.",
        },
        { img: require("../../assets/images/CaseStudy/3/P-3.png") },
      ],
    },
    {
      id: 4,
      projecttitile: "Aquis",
      banner_pera:
        "Hair Dry Luxe is a high-quality fabric that is designed to provide comfort while drying your hair. The unique blend of materials offers excellent absorbency and softness to ensure that your hair dries quickly without damaging it. Aquis, a leading manufacturer of hair towels, has created this fabric to provide the ultimate drying experience. The material is lightweight, compact, and quick-drying, making it perfect for use at home or on the go. ",
      banner_img: require("../../assets/images/CaseStudy/4/P-1.webp"),
      the_object:
        "The objective of the Aquis website project is to create a user-friendly and visually appealing online platform that effectively showcases the company's range of hair care products and provides customers with a seamless shopping experience. The website will be designed to provide customers with easy access to product information, features, and benefits, as well as to provide detailed information on the Hair Dry Luxe fabric. The website will also feature an online store where customers can purchase Aquis products with ease. The objective is to create a platform that not only informs and educates customers on the benefits of Aquis products but also provides a smooth and efficient shopping experience, resulting in increased customer satisfaction and brand loyalty.",
      unique_problem: [
        {
          title: "Visually Differentiation",
          discription:
            "Aquis has successfully differentiated itself visually through its minimalist and modern design approach. The company’s website, packaging, and branding all feature a clean, white background with bold, sans-serif text in a shade of blue. The use of white and blue creates a sense of calm and purity, aligning with the brand's message of providing gentle and effective hair care solutions. The minimalist design is also reflected in the product design, with Aquis hair towels featuring a simple, elegant look. This visual differentiation sets Aquis apart from its competitors, who may have a more traditional or busy design approach. The clean, modern look of Aquis is appealing to customers who are seeking a premium and high-quality hair care experience.",
        },
        {
          title: "Maintaining Lightening Speed",
          discription:
            "The Aquis website has been optimized for lightning speed, ensuring a seamless and efficient browsing experience for customers. With fast loading times, customers are able to quickly and easily access the information they need, whether it's product information, customer reviews, or the online store. The website has been designed with a focus on user experience, ensuring that customers can quickly and easily find the information they need. The use of optimized images and minimalistic design also contributes to the lightning speed of the website, providing customers with a smooth and seamless browsing experience. The fast loading times and optimized design ensure that customers are able to quickly and easily complete their purchases, resulting in increased customer satisfaction and brand loyalty.",
        },
      ],
      whatwedid: [
        { list: "Digital Strategy" },
        { list: "UI/UX Design" },
        { list: "Design and Development" },
        { list: "Web Development" },
        { list: "Content Strategy" },
        { list: "Copywriting" },
      ],
      platform: [{ list: "Shopify" }, { list: "CMS" }, { list: "SEO Optimize" }],
      result: [{ overall: 68 }, { bounce: 27 }, { value: 10 }],
      home: [
        {
          text: "The homepage includes several pops of color that highlights the products as well as the branding of Luseta Beauty. The product images on the homepage scrolls to show more product images and shows a mix of lifestyle and product images.",
        },
        { img: require("../../assets/images/CaseStudy/4/P-2.png") },
      ],
      section1: [
        { heading: "Website Navigation" },
        {
          pera: "The site navigation is laid out in a clean and concise manner making it easy to find each product page based upon best selling products, value sets, collections, etc.",
        },
        { img: require("../../assets/images/CaseStudy/4/C-1.png") },
      ],
      section2: [
        { heading: "Add To Cart Experience" },
        {
          pera: "The side cart allows users to to continue to shop while also being able to to view their shopping cart. The free shipping experience is an added bonus that entices customers to want to shop more!",
        },
        { img: require("../../assets/images/CaseStudy/4/C-2.png") },
      ],
      section3: [
        { heading: "Shirt Customization" },
        {
          pera: "Clients hire us based on our merit – they love us for our accessibility. We go to great lengths to be incredibly responsive to your needs so you never feel ignored and always feel taken care of.",
        },
        { img: require("../../assets/images/CaseStudy/4/C-3.png") },
      ],
      collection: [
        {
          text: "The collection pages can be found via the navigation or each through the Ingredient Slider. These pages are set up to highlight key ingredients or traits of a collection of products. Again creating a user experience that allows for customers to find products that meet their needs.",
        },
        { img: require("../../assets/images/CaseStudy/4/P-3.png") },
      ],
    },

  ],
};

// const Logo_DATA = Information.Sheet1.map(x=>{
//   return{ id: Math.random()* num+10,
//   All_data:x.Overview}
// })

//  Logo_DATA
