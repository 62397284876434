import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import PersonalInformation from "./PersonalInformation";
import "./brief.scss";
import WebisteBrief from "./WebisteBrief";
import { send, sendForm } from "emailjs-com";
import { useNavigate } from "react-router-dom";
import Logo from "./Logo";
import SelectBrief from "./SelectBrief";

function Brief() {
  const [step, setStep] = useState(1);
  const [form, setForm] = useState({
    name: "",
    email: "",
    contact: "",
    url: "",
    address: "",
    your_business: "",
    business_category: "",
    target_audiance: "",
    competitors_url: "",
    like_color: "",
    look_and_feel: "",
    existing_website: "",
    page_need: "",
    suggestions_site: "",
    minimum_page_resolution: "",
    any_other_file: "",
    accept_terms_and_condition: "",
    logo_name: "",
    tag_line: "",
    about_description: "",
    target_audiance: "",
    compitators_logo: "",
    any_idea_have: "",
    color_fonts: "",
    time_frame: "",
  });
  const nav = useNavigate();
  const [files, setFiles] = useState("");

  const handlechange = (e) => {
    // console.log(e.target.value);
    const d = e.target.value;
    setForm({ ...form, [e.target.name]: e.target.value });

    console.log(form);
  };

  const next = (e) => {
    // e.preventDefault();
    // if (form.name) {
    // if (form.name === "" || form.email === "" || form.contact === "") {
    //   alert("name is required");
    // //   setStep(1);
    // } else {
    setStep(step + 1);
    // }
    // }
  };

  // const backprevious = () => {
  //   setStep(2)
  // }

  const previous = () => {
    setStep(step - 1);
  };
  // const logobrief = () => {
  //   setStep(4)

  // }

  // const websitebrief = () => {
  //   setStep(3)
  // }

  const submit = (e) => {
    e.preventDefault();
    if (form.name === "" || form.email === "" || form.contact === "") {
      alert("'name' 'email' 'contect' ");

      //   setStep(1);
    } else {
      send("service_preji3l", "template_rkbilay", form, "bj4zJhw5NpWVXpE5D")
        .then((response) => {
          console.log("SUCCESS!", response.status, response.text);
          setTimeout(() => {
            nav("/thankyou");
          }, 1000);
        })
        .catch((err) => {
          console.log("FAILED...", err);
        });
    }
  };
  const BriefSteps = () => {
    switch (step) {
      case 1:
        return (
          <PersonalInformation
            next={() => next()}
            handlechange={(e) => handlechange(e)}
          />
        );

      case 2:
        return (
          <WebisteBrief
            next={() => next()}
            previous={() => previous()}
            handlechange={(e) => handlechange(e)}
          />
        );
      // case 3:
      //   return (
      //     <WebisteBrief
      //       next={() => next()}
      //       previous={() => previous()}
      //       handlechange={(e) => handlechange(e)}
      //     />
      //   );
      //   case 4:
      //   return (
      //     <Logo
      //       next={() => next()}
      //       backprevious={() => backprevious()}
      //       handlechange={(e) => handlechange(e)}
      //     />
      //   );
    }
  };
  return (
    <Container fluid className="brief_form_container">
      <Row>
        <Col sm={12} lg={5} className="brief_bg"></Col>
        <Col sm={12} lg={7} className="brief_form">
          <Row className="pb-lg-3 pb-3 justify-content-center">
            <Col lg={4} className="text-center pt-2 col-7 m-auto">
              <a href="/">
                <img src={require("../assets/images/Logo.webp")} width="100%" />
              </a>
            </Col>
          </Row>
          <Row className="brief_stepper pb-lg-3">
            <Col>
              <div>
                <ul>
                  <li className={step >= 1 ? "list_bg_color" : ""}>
                    <span></span>
                  </li>
                  <li className={step >= 2 ? "list_bg_color" : ""}>
                    <span></span>
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
          <Row className="brief_form_input">
            <Col className="m-auto">
              <form id="myForm" method="post" onSubmit={submit}>
                {BriefSteps()}
              </form>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
}

export default Brief;
