import "./App.scss";
import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Header from "./Component/Header/Header";
// import Scroll from "./Component/SmoothScroll";
import Footer from "./Component/Footer/Footer";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect, useState } from "react";
import Loading from "./Loading";
import { Suspense } from "react";
import { lazy } from "react";
import Process from "./Component/common/process/Process";
import PageNotFound from "./Component/404/PageNotFound";
import Brief from "./Component/brief/Brief";
import Customwebsite from "./Component/Lp/Customwebsite";
import LogoBrief from "./Component/brief/LogoBrief";
function App() {
  const Home = lazy(() => delayForDemo(import("./Component/Home/Home")));
  const About = lazy(() => delayForDemo(import("./Component/About/About")));
  const Partner = lazy(() =>
    delayForDemo(import("./Component/About/Partners/Partner"))
  );
  const Career = lazy(() =>
    delayForDemo(import("./Component/About/Career/Career"))
  );
  const Animation = lazy(() =>
    delayForDemo(import("./Component/Services/Animation/Animation"))
  );
  const Branding = lazy(() =>
    delayForDemo(import("./Component/Services/Branding/Branding"))
  );
  const Ourwork = lazy(() =>
    delayForDemo(import("./Component/OurWork/Ourwork"))
  );
  const Client = lazy(() =>
    delayForDemo(import("./Component/About/Client/Client"))
  );
  const Ecommerce = lazy(() =>
    delayForDemo(import("./Component/Services/Website/Ecommerce"))
  );
  const WordPress = lazy(() =>
    delayForDemo(import("./Component/Services/Website/WordPress"))
  );
  const Shopify = lazy(() =>
    delayForDemo(import("./Component/Services/Website/Shopify"))
  );
  const Smm = lazy(() => delayForDemo(import("./Component/Services/SMM/Smm")));
  const Seo = lazy(() => delayForDemo(import("./Component/Services/Seo/Seo")));
  const Thankyou = lazy(() =>
    delayForDemo(import("./Component/Thankyou/Thankyou"))
  );
  const Logo = lazy(() =>
    delayForDemo(import("./Component/Services/Logo/Logo"))
  );
  const UiUx = lazy(() =>
    delayForDemo(import("./Component/Services/Website/UiUx"))
  );
  const Hosting = lazy(() =>
    delayForDemo(import("./Component/Services/Hosting/Hosting"))
  );
  const Contact = lazy(() =>
    delayForDemo(import("./Component/ContactUs/Contact"))
  );
  const Packages = lazy(() =>
    delayForDemo(import("./Component/Packages/Packages"))
  );
  const PackageDetails = lazy(() =>
    delayForDemo(import("./Component/Packages/packagedetails/PackageDetails"))
  );
  const LocalSeo = lazy(() =>
    delayForDemo(import("./Component/Services/LocalSeo/LocalSeo"))
  );
  const ContentWriting = lazy(() =>
    delayForDemo(import("./Component/Services/ContentWriting/ContentWriting"))
  );
  const CustomWebsite = lazy(() =>
    delayForDemo(import("./Component/Services/CustomWebsite/CustomWebsite"))
  );
  const Insights = lazy(() =>
    delayForDemo(import("./Component/Insights/Insigts"))
  );
  const CaseStudies = lazy(() =>
    delayForDemo(import("./Component/CaseStudies/Casestudies"))
  );
  const PrivacyPolicy = lazy(() =>
    delayForDemo(import("./policypages/PrivacyPolicy"))
  );
  const TermsandCondition = lazy(() =>
    delayForDemo(import("./policypages/TermsandCondition"))
  );
  const [loading, setloading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setloading(false);
      window.scrollTo(0, 0);
    }, 1500);
    AOS.init();
    // onChatLoaded();
  }, []);
  const delayForDemo = (promise) => {
    return new Promise((resolve) => {
      setTimeout(resolve, 1500);
    }).then(() => promise);
  };

  return (
    <>
      <Router>
        {!loading ? <Header /> : ""}
        <div className="app">
          <Routes>
            {/* <ScrollToTop /> */}
            <Route
              exact
              path="/"
              element={
                <Suspense fallback={<Loading />}>
                  {" "}
                  <Home />
                </Suspense>
              }
            />
            <Route
              exact
              path="/about"
              element={
                <Suspense fallback={<Loading />}>
                  {" "}
                  <About />
                </Suspense>
              }
            />
            <Route
              exact
              path="/partner"
              element={
                <Suspense fallback={<Loading />}>
                  {" "}
                  <Partner />
                </Suspense>
              }
            />
            <Route
              exact
              path="/career"
              element={
                <Suspense fallback={<Loading />}>
                  {" "}
                  <Career />
                </Suspense>
              }
            />
            <Route
              exact
              path="/animation"
              element={
                <Suspense fallback={<Loading />}>
                  {" "}
                  <Animation />
                </Suspense>
              }
            />
            <Route
              exact
              path="/branding"
              element={
                <Suspense fallback={<Loading />}>
                  {" "}
                  <Branding />
                </Suspense>
              }
            />
            <Route
              exact
              path="/our-work"
              element={
                <Suspense fallback={<Loading />}>
                  {" "}
                  <Ourwork />
                </Suspense>
              }
            />
            <Route
              exact
              path="/client"
              element={
                <Suspense fallback={<Loading />}>
                  {" "}
                  <Client />
                </Suspense>
              }
            />
            <Route
              exact
              path="/ecommerce"
              element={
                <Suspense fallback={<Loading />}>
                  {" "}
                  <Ecommerce />
                </Suspense>
              }
            />
            <Route
              exact
              path="/wordpress"
              element={
                <Suspense fallback={<Loading />}>
                  {" "}
                  <WordPress />
                </Suspense>
              }
            />
            <Route
              exact
              path="/shopify"
              element={
                <Suspense fallback={<Loading />}>
                  {" "}
                  <Shopify />
                </Suspense>
              }
            />
            <Route
              exact
              path="/social-media-marketing"
              element={
                <Suspense fallback={<Loading />}>
                  {" "}
                  <Smm />
                </Suspense>
              }
            />
            <Route
              exact
              path="/seo"
              element={
                <Suspense fallback={<Loading />}>
                  {" "}
                  <Seo />
                </Suspense>
              }
            />
            <Route
              exact
              path="/thankyou"
              element={
                <Suspense fallback={<Loading />}>
                  {" "}
                  <Thankyou />
                </Suspense>
              }
            />
            <Route
              exact
              path="/logo"
              element={
                <Suspense fallback={<Loading />}>
                  {" "}
                  <Logo />
                </Suspense>
              }
            />
            <Route
              exact
              path="/uiux"
              element={
                <Suspense fallback={<Loading />}>
                  {" "}
                  <UiUx />
                </Suspense>
              }
            />
            <Route
              exact
              path="/hosting"
              element={
                <Suspense fallback={<Loading />}>
                  {" "}
                  <Hosting />
                </Suspense>
              }
            />
            <Route
              exact
              path="/contact"
              element={
                <Suspense fallback={<Loading />}>
                  {" "}
                  <Contact />
                </Suspense>
              }
            />
            <Route
              exact
              path="/packages"
              element={
                <Suspense fallback={<Loading />}>
                  {" "}
                  <Packages />
                </Suspense>
              }
            />
            <Route
              exact
              path="/packages/:details"
              element={
                <Suspense fallback={<Loading />}>
                  {" "}
                  <PackageDetails />
                </Suspense>
              }
            />
            <Route
              exact
              path="/local-seo"
              element={
                <Suspense fallback={<Loading />}>
                  {" "}
                  <LocalSeo />
                </Suspense>
              }
            />
            <Route
              exact
              path="/content-writing"
              element={
                <Suspense fallback={<Loading />}>
                  {" "}
                  <ContentWriting />
                </Suspense>
              }
            />
            <Route
              exact
              path="/custom-website"
              element={
                <Suspense fallback={<Loading />}>
                  {" "}
                  <CustomWebsite />
                </Suspense>
              }
            />
            <Route
              exact
              path="/casestudies/:details"
              element={
                <Suspense fallback={<Loading />}>
                  <CaseStudies />
                </Suspense>
              }
            />
                    <Route
              exact
              path="/casestudies/lp/custom/:details"
              element={
                <Suspense fallback={<Loading />}>
                  <CaseStudies />
                </Suspense>
              }
            />
            <Route
              exact
              path="/insights"
              element={
                <Suspense fallback={<Loading />}>
                  <Insights />
                </Suspense>
              }
            />
            <Route
              exact
              path="/privacy-policy"
              element={
                <Suspense fallback={<Loading />}>
                  <PrivacyPolicy />
                </Suspense>
              }
            />

            <Route
              exact
              path="/customwebsite"
              element={
                <Suspense fallback={<Loading />}>
                  <Customwebsite />
                </Suspense>
              }
            />
            <Route
              exact
              path="/terms-and-conditions"
              element={
                <Suspense fallback={<Loading />}>
                  <TermsandCondition />
                </Suspense>
              }
            />
            <Route exact path="/brief" element={<Brief />} />
            <Route exact path="/logo-brief" element={<LogoBrief />} />
            <Route exact path="/lp/custom-website" element={<Customwebsite />} />
            {/* <Route path="/404" element={<PageNotFound />} />
            <Route path="*" element={<Navigate replace to="/404" />} /> */}
            <Route path="/contact-us" element={<Navigate replace to="/contact" />} />
            {/* < to="/404" /> */}
          </Routes>
        </div>
        {!loading ? <Footer /> : ""}
      </Router>
    </>
  );
}

export default App;
