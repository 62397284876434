import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import "./lpform.scss";
import { send } from "emailjs-com";
import { useNavigate } from "react-router-dom";

function LpForm(props) {
  const [form, setForm] = useState({
    fname: "",
    lname: "",
    email: "",
    phone: "",
    radio_btn_yes: "",
    comapny: "",
    project_type: "",
    project_description: "",
  });

  const nav = useNavigate();
  const handleChange = (e) => {
    e.preventDefault();
    setForm({ ...form, [e.target.name]: e.target.value });

    console.log(form);
  };

  const submit = (e) => {
    e.preventDefault();
    send("service_preji3l", "template_cu48jcl", form, "bj4zJhw5NpWVXpE5D")
    .then((response) => {
      console.log("SUCCESS!", response.status, response.text);
      setTimeout(() => {
        nav("/thankyou");
      }, 1000);
    })
    .catch((err) => {
      console.log("FAILED...", err);
    });
  }

  return (
    <div id="signup" className="form_lp_customwebsite p-3">
      <Row>
        <h2>Request a Free Consultation Here</h2>
      </Row>
      <Row>
        <form method="POST" onSubmit={submit}>
          <Row className="input_row">
            <Col lg={6}>
              <input
                id="fname"
                name="fname"
                required
                type="text"
                onChange={(e) => handleChange(e)}
                placeholder="First Name"
                au
              />
            </Col>
            <Col lg={6}>
              <input
                id="lname"
                name="lname"
                required
                type="text"
                onChange={(e) => handleChange(e)}
                placeholder="Last Name"
              />
            </Col>
          </Row>
          <Row className="input_row">
            <Col lg={6}>
              <input
                id="email"
                name="email"
                type="email"
                required
                onChange={(e) => handleChange(e)}
                placeholder="Email Address"
              />
            </Col>
            <Col lg={6}>
              <input
                id="phone"
                name="phone"
                type="tel"
                required
                onChange={(e) => handleChange(e)}
                placeholder="Phone Number"
              />
            </Col>
          </Row>
          <Row className="input_row">
            <Col lg={6}>
              <label for="funded_website_radio">Is your project funded?*</label>
              <div className="d-flex justify-content-around">
                <div className="d-flex justify-content-between w-25">
                  <input
                    name="radio_btn_yes"
                    type="radio"
                    className="radio_btn_yes m-auto"
                    required
                    value="Yes"
                    onChange={(e) => handleChange(e)}
                    placeholder="Email Address"
                  />
                  <label className="" for="radio_btn_yes">
                    Yes
                  </label>
                </div>
                <div className="d-flex justify-content-between w-25">
                  <input
                    name="radio_btn_yes"
                    type="radio"
                    className="radio_btn_no m-auto"
                    required
                    value="No"
                    onChange={(e) => handleChange(e)}
                  />
                  <label className="" for="radio_btn_yes">
                    No
                  </label>
                </div>
              </div>
            </Col>
            <Col lg={6} className="m-auto mb-0">
              <input
                id="comapny"
                name="comapny"
                type="text"
                required
                onChange={(e) => handleChange(e)}
                placeholder="comapny"
              />
            </Col>
          </Row>
          <Row className="input_row">
            <Col lg={12}>
              <label for="project_type">Project Title</label>
              <input
                id="project_type"
                name="project_type"
                type="text"
                required
                onChange={(e) => handleChange(e)}
                // placeholder="Email Address"
              />
            </Col>
          </Row>
          <Row className="input_row">
            <Col lg={12}>
              <label for="project_description">Project Description</label>
              <textarea
                id="project_description"
                name="project_description"
                type="text"
                required
                onChange={(e) => handleChange(e)}
                // placeholder="Email Address"
              />
            </Col>
          </Row>
          <Row className="input_row text-center">
            <Col>
              <button>Get In Touch</button>
            </Col>
          </Row>
          <Row className="input_row text-white">
            <Col>
              <p>
                By submitting the form, you agree to send your information to
                Knots who agrees to use it according to their{" "}
                <a href="/privacy-policy">privacy policy</a>.
              </p>
              <p className="mt-2">
                If required, we can provide an NDA after an initial phone
                conversation.
              </p>
            </Col>
          </Row>
        </form>
      </Row>
    </div>
  );
}

export default LpForm;
