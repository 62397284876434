import React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import "./footer.scss";
import logo from "../assets/images/Logo.webp";
import facebook from "../assets/images/footer/px-facebook.webp";
import instagram from "../assets/images/footer/px-instagram.webp";
import twitter from "../assets/images/footer/px-twitter.webp";
import linkedin from "../assets/images/footer/linkedin.png";
import { BsArrowUp } from "react-icons/bs";
import { BsX } from "react-icons/bs";
import { send } from "emailjs-com";
import { useLocation, useNavigate } from "react-router-dom";
import {
  BsFillMapFill,
  BsFillTelephoneFill,
  BsEnvelopeOpenFill,
} from "react-icons/bs";

function Footer() {
  const [showBtp, setshowBtp] = React.useState(false);
  const [showLead, setShowLead] = React.useState(false);
  const [showPopup, setShowPopup] = React.useState(false);
  const [toSend, setToSend] = React.useState({});
  const location = useLocation();
  const backtotop = () => {
    window.scrollTo(0, 0);
  };
  const nav = useNavigate();

  React.useEffect(() => {
    window.addEventListener("scroll", shbtop);
    // window.addEventListener("onload",
    setTimeout(function () {
      if (
        location.pathname !== "/thankyou" &&
        location.pathname !== "/" &&
        location.pathname !== "/brief"
      ) {
        setShowPopup(true);
      }

      // alert("Hello World");
    }, 5000);
    // );
  }, []);
  console.log(location.pathname);
  const shbtop = () => {
    const scrollTop = window.scrollY;
    if (scrollTop > 1200) {
      setshowBtp(true);
    } else {
      setshowBtp(false);
    }
  };

  const submit = (e) => {
    e.preventDefault();
    send("service_g048fwg", "template_nfdkb6e", toSend, "JE-kELQ7Lo4pAD-Kl")
      .then((response) => {
        console.log("SUCCESS!", response.status, response.text);
        setTimeout(() => {
          nav("/thankyou");
        }, 1000);
        setShowLead(false);
        setShowPopup(false);
      })
      .catch((err) => {
        console.log("FAILED...", err);
      });
  };

  const handleChange = (e) => {
    e.preventDefault();
    setToSend({ ...toSend, [e.target.name]: e.target.value });
    console.log(toSend);
  };

  const showLeadForm = () => {
    if (showLead !== true) {
      setShowLead(true);
    } else {
      setShowLead(false);
    }
  };
  return location.pathname === "/brief" ||
    location.pathname === "/thankyou" ||
    location.pathname === "/logo-brief" ||
    location.pathname === "/lp/custom-website" ? (
    ""
  ) : (
    <footer>
      <Container fluid className="footer">
        <Container>
          <Row>
            <Col sm={12} md={12} lg={3}>
              <div>
                <a href="/">
                  <Image src={logo} width="268px" />
                </a>
                <p>
                  We help businesses like yours earn more customers,
                  differentiate from competitors, and recruit top talent with
                  their websites.
                </p>
                <ul className="social_links">
                  <li>
                    <a
                      href="https://www.facebook.com/knotsdigital"
                      target="_blank"
                    >
                      <Image src={facebook} alt="facebook" />
                    </a>
                  </li>
                  <li>
                    <a href="https://twitter.com/knotsdigital" target="_blank">
                      <Image src={twitter} alt="twitter" />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.instagram.com/knotsdigital/"
                      target="_blank"
                    >
                      <Image src={instagram} alt="instagram" />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.linkedin.com/company/knotsdigital/"
                      target="_blank"
                    >
                      <Image src={linkedin} alt="Linkedin" />
                    </a>
                  </li>
                </ul>
              </div>
            </Col>
            <Col sm={12} md={12} lg={3}>
              <div style={{ width: "70%", margin: "auto" }}>
                <h3>Contact</h3>
                <ul>
                  <li>
                    <a href="tel:203-920-4659">
                      <span>
                        <BsFillTelephoneFill />
                      </span>{" "}
                      203-920-4659
                    </a>
                  </li>
                  <br />
                  <li>
                    <a href="mailto:info@knotsdigital.com">
                      <span>
                        <BsEnvelopeOpenFill />{" "}
                      </span>
                      info@knotsdigital.com
                    </a>
                  </li>
                  <br />
                  <li>
                    {/* <a href="#" disabled> */}
                    <span>
                      <BsFillMapFill />{" "}
                    </span>
                    157 Church Street 19th Floor, New Haven, CT 06510
                    {/* </a> */}
                  </li>
                </ul>
              </div>
            </Col>
            <Col sm={12} md={12} lg={3}>
              <div style={{ width: "50%", margin: "auto" }}>
                <h3>Services</h3>
                <ul>
                  <li>
                    <a href="/logo">Logo Design</a>
                  </li>
                  <li>
                    <a href="/wordpress">WordPress</a>
                  </li>
                  <li>
                    <a href="/ecommerce">E-Commerce</a>
                  </li>
                  <li>
                    <a href="/shopify">Shopify</a>
                  </li>
                  <li>
                    <a href="/uiux">Ui/Ux</a>
                  </li>
                  <li>
                    <a href="/branding">Branding</a>
                  </li>
                  <li>
                    <a href="/animation">Animations</a>
                  </li>
                  <li>
                    <a href="/seo">SEO</a>
                  </li>
                </ul>
              </div>
            </Col>
            <Col sm={12} md={12} lg={3}>
              <div>
                <h3>Usefull Links</h3>
                <ul>
                  <li>
                    <a href={"/about"}>Why choose Knots Design?</a>
                  </li>
                  <li>
                    <a href={"/career"}>Career</a>
                  </li>
                  <li>
                    <a href={"/client"}>Clients</a>
                  </li>
                  <li>
                    <a href={"/partner"}>Partner</a>
                  </li>
                  {/* <li>
                    <Link href={'/partner'}>
                        News
                    </Link>
                </li> */}
                </ul>
                <img
                  src={require("../assets/images/payment.png")}
                  width="100%"
                  style={{ borderRadius: "10px" }}
                />
              </div>
            </Col>
          </Row>
        </Container>
        <Container className="g-0 footer_bootom">
          <Row className="footer_bootom_row">
            <Col className="" sm={12}>
              <p>2022 - KnotsDigital. ALL Rights Reserved.</p>
            </Col>
            <Col className="" sm={12}>
              <ul className="d-flex justify-content-end">
                <li className="text-end" style={{ paddingRight: "10px" }}>
                  <a className="" href="/terms-and-conditions">
                    Terms And Conditions
                  </a>
                </li>
                <li className="">
                  <a href="/privacy-policy">Privacy Policy</a>
                </li>
              </ul>
            </Col>
          </Row>
        </Container>

        {showBtp ? (
          <div className="backtotop" onClick={backtotop}>
            <BsArrowUp fill="#032e32" width={32} height={32} />
          </div>
        ) : (
          ""
        )}
        <div className="caal_toaction">
          <a href="tel:203-920-4659">
            <BsFillTelephoneFill fill="#032e32" width={32} height={32} />
          </a>
        </div>
      </Container>
      {/* <div */}
      {/* // onClick={() => setShowLead(true)}
        className={showLead == true ? "lead_form lead_form_hover" : "lead_form"}
      >
        <span
          className={showLead !== true ? "span_hover" : ""}
          onClick={() => showLeadForm()}
        >
          Get 60% off on all services
        </span>
        <h2>Chat With Us to Avail 60% Discount</h2>
        <form onSubmit={submit}>
          <div className="main_form">
            <div> */}
      {/* <label>Na</label> */}
      {/* <input
                type="text"
                placeholder="Your Name"
                onChange={handleChange}
                name="name"
              />
            </div>
            <div> */}
      {/* <label></label> */}
      {/* <input
                type="email"
                placeholder="Email Address"
                onChange={handleChange}
                name="email"
              />
            </div>
            <div> */}
      {/* <label></label> */}
      {/* <input
                type="tel"
                placeholder="000-00-000"
                pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
                onChange={handleChange}
                name="pn"
              />
            </div>
            <div> */}
      {/* <label></label> */}
      {/* <textarea
                maxlength="50"
                placeholder="Message"
                onChange={handleChange}
                name="msg"
              />
            </div>
            <div>
              <button type="submit" className="btn_pink">
                Get 60% discount
              </button>
            </div>
          </div>
        </form>
      </div> */}
      {showPopup == true ? (
        <div className="pop_bg">
          <div className="popp_form">
            <Container className="pop_container">
              <Row>
                <Col sm={4} className="p-0">
                  <div className="pop_bg_img">
                    <div className="pt-5">
                      <h3>New Year Special Offer</h3>
                      <p>
                        Fill up the form and our team will get back to you
                        within 24 hours.
                      </p>
                    </div>
                    <div className="web_details">
                      <ul style={{ listStyle: "none", fontSize: "1rem" }}>
                        <li>
                          <a href="tel:203-920-4659">
                            <span>
                              <BsFillTelephoneFill />{" "}
                            </span>
                            203-920-4659
                          </a>
                        </li>
                        <li>
                          <a href="mailto:sales@knotsdigital.com">
                            <span>
                              <BsEnvelopeOpenFill />{" "}
                            </span>
                            sales@knotsdigital.com
                          </a>
                        </li>
                        <li>
                          <span>
                            <BsFillMapFill />{" "}
                          </span>
                          157 Church Street 19th Floor, New Haven, CT 06510
                        </li>
                      </ul>
                    </div>
                  </div>
                </Col>
                <Col className="p-0" sm={8}>
                  {/* <h2>Chat With Us to Avail 60% Discount</h2>
                  <p>
                    Heads up! We require that you sign up for web builder hub
                    services and packages. We make all your dreams come true in
                    a successful project.
                  </p> */}
                  <form onSubmit={submit} className="pt-5">
                    <div className="main_form">
                      <div
                        class="close_btn"
                        onClick={() => setShowPopup(false)}
                      >
                        <BsX fill="white" />
                      </div>
                      <div className="pop_input_dev">
                        {/* <label>Na</label> */}
                        <input
                          type="text"
                          placeholder="First Name"
                          onChange={handleChange}
                          name="fname"
                        />
                        <input
                          type="text"
                          placeholder="Last Name"
                          onChange={handleChange}
                          name="lname"
                        />
                      </div>
                      <div className="pop_input_dev">
                        {/* <label></label> */}
                        <input
                          type="email"
                          placeholder="Email Address"
                          onChange={handleChange}
                          name="email"
                        />
                        <input
                          type="tel"
                          placeholder="123-45-678"
                          pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
                          onChange={handleChange}
                          name="pn"
                        />
                      </div>
                      <div>
                        <p className="" style={{ textAlign: "left" }}>
                          What service do you need?
                        </p>
                        <br />
                        <div className="pop_input_dev d_mnl_flx">
                          <div>
                            <input
                              type="radio"
                              id="webdesign"
                              name="service"
                              onChange={handleChange}
                              value="Web Design"
                            />
                            <span>
                              <label for="webdesign">Web Design</label>
                            </span>
                          </div>

                          <div>
                            <input
                              type="radio"
                              id="webdevelopment"
                              name="service"
                              onChange={handleChange}
                              value="Web Development"
                            />
                            <span>
                              <label for="webdevelopment">Development</label>
                            </span>
                          </div>
                          <div>
                            <input
                              type="radio"
                              id="logodesign"
                              name="service"
                              onChange={handleChange}
                              value="Logo Design"
                            />
                            <span>
                              <label for="logodesign">Logo Design</label>
                            </span>
                          </div>
                          <div>
                            <input
                              type="radio"
                              id="other"
                              name="service"
                              onChange={handleChange}
                              value="Other"
                            />
                            <span>
                              <label for="other">Other</label>
                            </span>
                          </div>
                        </div>
                      </div>
                      <br />
                      <div>
                        {/* <label></label> */}
                        <textarea
                          maxlength="50"
                          placeholder="Message"
                          onChange={handleChange}
                          name="msg"
                        />
                      </div>
                      <div>
                        <button type="submit" className="btn_pink">
                          Send Message
                        </button>
                      </div>
                    </div>
                  </form>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      ) : null}
    </footer>
  );
}

export default Footer;
