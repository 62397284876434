import { Container, Row, Col, Image } from "react-bootstrap";
import Slider from "react-slick";
import logo from "../../Component/assets/images/Logo.webp";
import mainBanner from "../../Component/assets/images/LandingPage/CustomWebsite/Main1.png";
import cardImageOne from "../../Component/assets/images/LandingPage/CustomWebsite/sonoture-bg-black-174d3a5f993711810de2350d8367d74e.jpg";
import cardImageSecond from "../../Component/assets/images/LandingPage/CustomWebsite/linc-06f589a0988033894a9f7c42872f9fcf.jpg";
import mobileImage from "../../Component/assets/images/LandingPage/CustomWebsite/retail-solutions-d726c400e30afea43f5494ffd798b38a.png";
import focusIcon1 from "../../Component/assets/images/LandingPage/CustomWebsite/rocket-dark-00662aa9f4bd0c60a58c76cf229ea5b3.svg";
import focusIcon2 from "../../Component/assets/images/LandingPage/CustomWebsite/enterprise-dark-f92ec7c9f769ed057615c1922cfd8377.svg";
import focusIcon3 from "../../Component/assets/images/LandingPage/CustomWebsite/strategy-dark-f6cb9e1ad5e1d6fa947a568b4fd33e33.svg";
import focusIcon4 from "../../Component/assets/images/LandingPage/CustomWebsite/rescue-dark-852d3a74adb258c98cc30ddc3e22b34a.svg";
import partnerLogo1 from "../../Component/assets/images/LandingPage/CustomWebsite/aws-partner-white-d46a140846437d849d37eefe2d6a0500.svg";
import partnerLogo2 from "../../Component/assets/images/LandingPage/CustomWebsite/clutch-11341999ba85f575516152fed10747cf.png";
import partnerLogo3 from "../../Component/assets/images/LandingPage/CustomWebsite/shopify-logo-white@2x-9bc4c016f576423dd5b7b8c05d557d94.png";
import partnerLogo4 from "../../Component/assets/images/LandingPage/CustomWebsite/indigo-awards-a195d85e36aaedf632c43ba590a8a622.png";
import partnerLogo5 from "../../Component/assets/images/LandingPage/CustomWebsite/istqb-logo-white@2x-a0825fb51eb6b7726814d60283da7798.png";
import partnerLogo6 from "../../Component/assets/images/LandingPage/CustomWebsite/clutch-developers-992c7123929026e821bb00b7c7d6b642.png";
import InterestServicesIllustrationImage from "../../Component/assets/images/LandingPage/CustomWebsite/about_koombea_img_0.png";
import customWebsiteBrandChooseLogo1 from "../../Component/assets/images/LandingPage/CustomWebsite/ebay-logo-white-46d937d5a73704b4580ba4f7b054ceca.svg";
import customWebsiteBrandChooseLogo2 from "../../Component/assets/images/LandingPage/CustomWebsite/luna-logo-white-6097f2ac0b2bdad412ff8e43ca4fbd6b.svg";
import customWebsiteBrandChooseLogo3 from "../../Component/assets/images/LandingPage/CustomWebsite/payix-logo-white-72caea0b23e8a3780e3802d526542555.svg";
import customWebsiteBrandChooseLogo4 from "../../Component/assets/images/LandingPage/CustomWebsite/linc-logo-white-ef0af8a57a81e89916da6feddbc8e18d.svg";
import clientReview from "../../Component/assets/images/LandingPage/CustomWebsite/Layer 8.png";
import socialIconFacebook from "../../Component/assets/images/LandingPage/CustomWebsite/facebook-icon-circle-fef3de37b1a204601e30b57d49b3aafc.svg";
import socialIconInstagram from "../../Component/assets/images/LandingPage/CustomWebsite/instagram-icon-circle-35752517459c66ba433bd4652382211d.svg";
import socialIconTwitter from "../../Component/assets/images/LandingPage/CustomWebsite/twitter-icon-circle-bc4eb2dbdadd6d590e88b895db667083.svg";
import socialIconLinkedin from "../../Component/assets/images/LandingPage/CustomWebsite/linkedin-icon-circle-262728c2a11991a398692a91c868512e.svg";
import LpForm from "./Form/LpForm";
import "./customwebsite.scss";
import Work from "./work/Work";
import LpPricing from "./Pricing/LpPricing";

function Customwebsite() {
  return (
    <Container fluid className="customWebsite_main_Container">
      <Container className="customWebsiteHeader_Container">
        <Row className="customWebsiteHeader_row ">
          <Col className="customWebsiteHeader_Col col-6" lg={3}>
            <Image className="logo w-100 pt-3 pb-3" src={logo} />
          </Col>
          <Col className="phone_lp_col col-6 m-auto text-right">
            <a href="tel:203-920-4659">
              203-920-4659{" "}
              <span>
                {" "}
                <img src={require("../assets/images/phone.png")} />
              </span>
            </a>
          </Col>
        </Row>
      </Container>
      <Container className="customWebsite_Continer">
        <Row className="customWebsiteLeft_main_Row">
          <Col lg={8} className="customWebsiteLeft_Col">
            <Container className="customWebsiteLeft_Container">
              <Row className="customWebsite_Row">
                <Col className="customWebsite_banner">
                  <img src={mainBanner} width={"100%"} />
                </Col>
              </Row>
            </Container>
            <Container className="customWebsiteContent_Container">
              <Row className="customWebsiteContent_Row">
                <Col className="customWebsiteContent_Col">
                  <h1>Success Starts with the Right Web Development Company</h1>
                  <p>
                    For more than a decade, we have been creating high
                    performance, feature-packed web and mobile applications for
                    all major platforms including Android and iOS.<br></br>
                    <br></br>{" "}
                    <b>
                      {" "}
                      As an expert in software development, Knots digital can
                      create the web that will meet all your business and
                      industry needs by combining technical proficiency with
                      ease-of-use.
                    </b>
                  </p>
                </Col>
              </Row>
            </Container>
            <Container className="customWebsiteOurWork_Container">
              <Row className="customWebsiteOurWork_Row">
                <Col className="customWebsiteOurWork_Col">
                  <h2>Our Work</h2>
                  <p>
                    We have built hundreds of apps. Some of them have been
                    acquired by{" "}
                    <strong>
                      Google, Motorola Solutions, Demandforce, Facebook, Skype,
                      and other companies.
                    </strong>
                  </p>
                </Col>
              </Row>
              <Work />
            </Container>
            <Container className="customWebsiteMethodology_Container">
              <Row className="customWebsiteMethodologyContent_Row">
                <Col className="customWebsiteMethodologyContent_Col">
                  <h2>
                    <strong>Our Methodology</strong>
                  </h2>
                  <p>
                    We Build Top-notch Apps, No Matter the Choice of Platform.
                    <br></br>
                    <br></br>
                    Our agile development methodology is proven to create highly
                    flexible engagement models which encompass a comprehensive
                    evaluation of your business requirements and goals to ensure
                    an ultimate solution that is both cost-effective and
                    efficient.
                  </p>
                </Col>
              </Row>
              <Row className="customWebsiteMethodologyImage_Row">
                <Col lg={8} className="customWebsiteMethodologyImage_Col">
                  <img src={mobileImage} />
                </Col>
              </Row>
            </Container>
            <Container className="customWebsiteProcess_Container">
              <Row className="customWebsiteProcess_Row">
                <Col className="customWebsiteProcess_Col">
                  <h2>Our Process</h2>
                  <h4>You share your vision and your goals.</h4>
                  <p>
                    Share your ideas, requirements, and expectations with us.
                    We'll look into your project free of charge.
                  </p>
                  <h4>We proceed.</h4>
                  <p>
                    We review and investigate your requirements thoroughly, we
                    test your idea and do a deep code review (if applicable),
                    then we identify the best possible tech solutions for your
                    project.
                  </p>
                  <h4>You get:</h4>
                  <p>
                    <ul>
                      <li>Detailed project scope through user stories.</li>
                      <li>Costs and time estimations.</li>
                      <li>
                        Professional analysis and consultation on your project.
                      </li>
                      <li>
                        Suggestions on tools, methods, and solutions needed.
                      </li>
                    </ul>
                  </p>
                </Col>
              </Row>
            </Container>
            <Container className="customWebsiteFocus_Container">
              <Row className="customWebsiteFocus_Row">
                <h2>Our Focus</h2>
                <Col lg={6} className="customWebsiteFocus_1_Col">
                  <div className="customWebsiteFocus_1_div d-flex">
                    <img src={focusIcon1} />
                    <p>Website Development For Startups</p>
                  </div>
                </Col>
                <Col lg={6} className="customWebsiteFocus_2_Col">
                  <div className="customWebsiteFocus_2_div d-flex">
                    <img src={focusIcon2} />
                    <p>Website Development For Business</p>
                  </div>
                </Col>
                <Col lg={6} className="customWebsiteFocus_3_Col">
                  <div className="customWebsiteFocus_3_div d-flex">
                    {" "}
                    <img src={focusIcon3} />
                    <p>Product Planning & Strategy</p>
                  </div>
                </Col>
                <Col lg={6} className="customWebsiteFocus_4_Col">
                  <div className="customWebsiteFocus_4_div d-flex">
                    {" "}
                    <img src={focusIcon4} />
                    <p>Rescue Projects</p>
                  </div>
                </Col>
              </Row>
            </Container>
            <Container className="customWebsitePricing_Container">
              <LpPricing />
            </Container>
            <Container className="customWebsiteCertification_Container">
              <Row className="customWebsiteCertification_Row">
                <h2>Partnerships and Certifications</h2>
                <Col lg={2} className="customWebsiteCertification_1_Col col-6">
                  <div className="customWebsiteCertification_1_div">
                    <img src={partnerLogo1} />
                  </div>
                </Col>
                <Col lg={2} className="customWebsiteCertification_2_Col col-6">
                  <div className="customWebsiteCertification_2_div">
                    {" "}
                    <img src={partnerLogo2} />
                  </div>
                </Col>
                <Col lg={2} className="customWebsiteCertification_3_Col col-6">
                  <div className="customWebsiteCertification_3_div">
                    <img src={partnerLogo3} />
                  </div>
                </Col>
                <Col lg={2} className="customWebsiteCertification_4_Col col-6">
                  <div className="customWebsiteCertification_4_div">
                    <img src={partnerLogo4} />
                  </div>
                </Col>{" "}
                <Col lg={2} className="customWebsiteCertification_5_Col col-6">
                  <div className="customWebsiteCertification_5_div">
                    <img src={partnerLogo5} />
                  </div>
                </Col>{" "}
                <Col lg={2} className="customWebsiteCertification_6_Col col-6">
                  <div className="customWebsiteCertification_6_div">
                    <img src={partnerLogo6} />
                  </div>
                </Col>
              </Row>
            </Container>
            <Container className="customWebsiteInterestServices_Container">
              <Row className="customWebsiteInterestServices_Row">
                <h2>Interested in Our Servcies?</h2>
                <Col
                  lg={6}
                  className="customWebsiteInterestServicesIllustration_Col"
                >
                  <img src={InterestServicesIllustrationImage} />
                </Col>
                <Col
                  lg={6}
                  className="customWebsiteInterestServicesContent_Col"
                >
                  <ul>
                    <li>UX/UI Design</li>
                    <li>Webstie Development</li>
                    <li>E-Commerce Website</li>
                    <li>Business Website</li>
                    <li>Blog Website</li>
                    <li>Portfolio Website</li>
                    <li>Personal Website</li>
                    <li>Nonprofit Website</li>
                  </ul>
                </Col>
              </Row>
            </Container>
            <Container className="customWebsiteBrandChoose_Container">
              <Row className="customWebsiteBrandChoose_Row">
                <Col className="customWebsiteBrandChoose_Col">
                  <h2>Great brands choose us, Why don't you?</h2>
                </Col>
              </Row>
              <Row className="customWebsiteBrandChooseLogo_Row">
                <Col lg={2} className="customWebsiteBrandChooseLogo1_Col col-6">
                  <img src={customWebsiteBrandChooseLogo1} />
                </Col>
                <Col lg={2} className="customWebsiteBrandChooseLogo2_Col col-6">
                  <img src={customWebsiteBrandChooseLogo2} />
                </Col>
                <Col lg={2} className="customWebsiteBrandChooseLogo3_Col col-6">
                  <img src={customWebsiteBrandChooseLogo3} />
                </Col>
                <Col lg={2} className="customWebsiteBrandChooseLogo4_Col col-6">
                  <img src={customWebsiteBrandChooseLogo4} />
                </Col>
              </Row>
            </Container>
            <Container className="clientReview_container">
              <Row className="clientReview_Row">
                <Col lg={10} className="clientReview_Col">
                  <img src={clientReview} />
                </Col>
              </Row>
            </Container>
          </Col>
          <Col style={{zIndex:1000}} className="customWebsteRight_Col">
            <LpForm />
          </Col>
        </Row>
      </Container>
      <Container fluid className="footer_Container g-0">
        <Row className="footer_Row justify-content-between">
          <Col sm={2} className="footerLeft_Col ">
            <Row className="footerLeft_Row">
              <Col lg={3} className="footerFacebook_Col col-2">
                <a href="https://www.facebook.com/knotsdigital" target="_blank ">
                  <img src={socialIconFacebook} />
                </a>
              </Col>
              <Col lg={3} className="footerInstagram_Col col-2">
                <a href="https://www.instagram.com/knotsdigital/" target="_blank ">
                  <img src={socialIconInstagram} />
                </a>
              </Col>
              <Col lg={3} className="footerTwitter_Col col-2">
                <a href="https://twitter.com/knotsdigital" target="_blank ">
                  <img src={socialIconTwitter} />
                </a>
              </Col>
              <Col lg={3} className="footerLinkedin_Col col-2">
                <a href="https://www.linkedin.com/company/knotsdigital/" target="_blank ">
                  <img src={socialIconLinkedin} />{" "}
                </a>
              </Col>
            </Row>
          </Col>
          <Col lg={8} className="footerRight_Col m-auto">
            <Row className="footerRight_Row">
              <p>
                <strong>
                  <a href="tel:">+1-203-920-4659</a>{" "}
                </strong>
                <span>
                  <strong>
                    @2007-2023 knotsdigital, Inc All Right Reserved
                  </strong>
                </span>
                <a href="/privacy-policy" target="_blank">
                  Privacy policy
                </a>{" "}
                /{" "}
                <a href="/sitemap.xml" target="_blank">
                  Site Map
                </a>
              </p>
            </Row>
          </Col>
        </Row>
      </Container>
      <div className="sign_up">
        <a href="#signup"><img src={require("../assets/images/touch.png")} /></a>
      </div>
    </Container>
  );
}
export default Customwebsite;
