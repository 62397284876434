import React, { useState } from "react";
import { memo } from "react";
import { Image } from "react-bootstrap";
import "./loading.scss";

const Loading = memo(function Loading() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div style={{ height: "100vh", width: "100%" }}>
      <div className="loading">
        {/* <div></div>
        <div></div> */}
        {/* <Image src={require('./Component/assets/images/loader.gif')} /> */}
        <div></div>
        <div></div>
        {/* <div></div> */}
      </div>
    </div>
  );
});

export default Loading;
