import React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import "./casestudy.scss";
import img5 from "../../assets/images/Services/UiUx/px-Layer 22.webp";
import img6 from "../../assets/images/Services/UiUx/px-Layer 23.webp";
import { CaseStudyInormation } from "../../Packages/PackageIncludes/Logo";
import { useNavigate } from "react-router-dom";

function CaseStudy() {
  const nav = useNavigate();
  const DetailPage = (id) => {
    nav("/casestudies/" + id);
  };
  return (
    <Container className="casestudy_section">
      <Row className="casestudy_section_row">
        <Col>
          <div>
            <h3 data-aos="fade-up">Knot’s Recent Custom-Design Projects.</h3>
            <p data-aos="fade-up">
              Here is a look at our UI/UX projects. Looking for more samples?
            </p>
            {/* <button data-aos="fade-up" className="btn_pink">
              Get all Case Studies
            </button> */}
          </div>
          <Row className="inner_row mt-5">
            {CaseStudyInormation.sheet1
              .filter((name) => name.id > 1)
              .map((d, i) => {
                return (
                  <Col sm={12} md={12} lg={4}>
                    <Image src={d.banner_img} data-aos="fade-up" />
                    {/* <h5 data-aos="fade-up">{d.projecttitile}</h5> */}
                    <h3 data-aos="fade-up">{d.projecttitile}</h3>
                    <p data-aos="fade-up">SaaS | WordPress | B2B | Build</p>
                    <button
                      className="btn_pink"
                      data-aos="fade-up"
                      onClick={() => DetailPage(d.id)}
                    >
                      VIEW CASE STUDY
                    </button>
                  </Col>
                );
              })}

            {/* <Col sm={12} md={12} lg={6}>
              <Image src={img6} data-aos="fade-up" />
              <h5 data-aos="fade-up">Technology</h5>
              <h3 data-aos="fade-up">EnergyHub</h3>
              <p data-aos="fade-up">SaaS | WordPress | B2B | Build</p>
              <button className="btn_pink" data-aos="fade-up">
                VIEW CASE STUDY
              </button>
            </Col> */}
          </Row>
        </Col>
      </Row>
    </Container>
  );
}

export default CaseStudy;
