import React from "react";
import { Col, Container, Row } from "react-bootstrap";

function WebisteBrief(props) {
  return (
    <div className="personal_details">
      <h2>Website Brief</h2>
      <Row className="pb-2 pt-2">
        <Col>
          <label for="your_business">Description of your business:</label>
          <input
            id="your_business"
            name="your_business"
            type="text"
            onChange={props.handlechange}
            required
          />
        </Col>
      </Row>
      <Row className="pb-2 pt-2">
        <Col>
          <label for="business_category">
            What industry your business falls into? eg: eCommerce, Business,
            Blog, Portfolio, Event, Personal, Membership, Nonprofit, etc...
          </label>
          <input
            id="business_category"
            name="business_category"
            type="text"
            // placeholder="yourname@email.com"
            onChange={props.handlechange}
          />
        </Col>
      </Row>
      <Row className="pb-2 pt-2">
        <Col>
          <label for="target_audiance">Who is your target audience?</label>
          <input
            id="target_audiance"
            name="target_audiance"
            type="text"
            placeholder="i.e. ( consumer, Student, Business )"
            onChange={props.handlechange}
          />
        </Col>
      </Row>
      <Row className="pb-2 pt-2">
        <Col>
          <label for="competitors_url">
            Who are your competitors? Please list their website addresses.
          </label>
          <input
            name="competitors_url"
            id="competitors_url"
            type="url"
            // placeholder="Your Website"
            onChange={props.handlechange}
          />
        </Col>
      </Row>
      <Row className="pb-2 pt-2">
        <Col>
          <label for="like_color">
            What colors do you like (and don't like)
          </label>
          <input
            id="like_color"
            name="like_color"
            type="text"
            // placeholder="Complete Address"
            onChange={props.handlechange}
          />
        </Col>
      </Row>
      <Row className="pb-2 pt-2">
        <Col>
          <label for="look_and_feel">
            What type of overall feeling would you like to project with your new
            site?
          </label>
          <input
            id="look_and_feel"
            name="look_and_feel"
            type="text"
            placeholder="i.e. Corporate, Trendy, Hi-tech, Fun & Friendly"
            onChange={props.handlechange}
          />
        </Col>
      </Row>
      <Row className="pb-2 pt-2">
        <Col>
          <label for="existing_website">
            Existing website address or domain name. If you do not have a domain
            name, which name would you like us to register for you?
          </label>
          <input
            id="existing_website"
            name="existing_website"
            type="text"
            // placeholder="i.e. Corporate, Trendy, Hi-tech, Fun & Friendly"
            onChange={props.handlechange}
          />
        </Col>
      </Row>
      <Row className="pb-2 pt-2">
        <Col>
          <label for="existing_website">
            What pages would you like in your website, Please name them here?
          </label>
          <input
            id="page_need"
            name="page_need"
            type="text"
            placeholder="i.e. Home, About US, Contact US"
            onChange={props.handlechange}
          />
        </Col>
      </Row>
      <Row className="pb-2 pt-2">
        <Col>
          <label for="existing_website">
            Anything else that we should keep in mind while designing your
            website?
          </label>
          <input
            id="suggestions_site"
            name="suggestions_site"
            type="text"
            placeholder="i.e. Home, About US, Contact US"
            onChange={props.handlechange}
          />
        </Col>
      </Row>
      <Row className="pb-2 pt-2">
        <Col>
          <label for="minimum_page_resolution">
            What should be the Minimum page resolution for your new website?
            (You can paste a link of example website that you like)
          </label>
          <input
            id="minimum_page_resolution"
            name="minimum_page_resolution"
            type="file"
            accept=".png, .jpg"
            // placeholder="i.e. Home, About US, Contact US"
            onChange={props.handlechange}
          />
        </Col>
      </Row>
      <Row className="pb-2 pt-2">
        <Col>
          <label for="any_other_file">
            If your website contents are ready, please upload them in a word or
            text file. If you already have a logo file, please upload it here.
          </label>
          <input
            id="any_other_file"
            name="any_other_file"
            type="file"
            // placeholder="i.e. Home, About US, Contact US"
            accept=".doc,.docx,application/msword, .txt, .png, .jpg"
            onChange={props.handlechange}
          />
        </Col>
      </Row>
      <Row className="pb-2 pt-2">
        <Col>
          <Row>
            <Col className="p-0 m-0" sm={1}>
              <input
                id="accept_terms_and_condition"
                type="checkbox"
                name="accept_terms_and_condition"
                onChange={props.handlechange}
                required
              />
            </Col>
            <Col className="p-0 m-0">
              <label for="accept_terms_and_condition">
                I agree to{" "}
                <a
                  href="https://www.knotsdigital.com/privacy-policy"
                  target="_blank"
                >
                  Terms of Services
                </a>{" "}
                and
                <a
                  href="https://www.knotsdigital.com/privacy-policy"
                  target="_blank"
                >
                  {" "}
                  Privacy Policy
                </a>
                . *
              </label>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="justify-content-start">
        <Col className="col-6" lg={4}>
          <button className="brief_btn text-black" onClick={props.previous}>
            Previous
          </button>
        </Col>
        <Col className="col-6" lg={4}>
          <button className="brief_btn text-black" type="submit">
            Submit
          </button>
        </Col>
      </Row>
    </div>
  );
}

export default WebisteBrief;
