import React from "react";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { Container, Image, Row, Col } from "react-bootstrap";
import { BsDashLg } from "react-icons/bs";
import { BsFillCaretDownFill } from "react-icons/bs";

function MobileHeader(props) {
  const [navClicked, setNavClicked] = React.useState(false);
  const [serviceNav, setserviceNav] = React.useState(false);
  const navBarClicked = () => {
    if (navClicked !== true) {
      setNavClicked(true);
    } else {
      setNavClicked(false);
    }
  };
  const navBarUnClicked = () => {
    setNavClicked(false);
    console.log(navClicked);
  };

  const showService = () => {
    if (serviceNav !== true) {
      setserviceNav(true);
    } else {
      setserviceNav(false);
    }
  };

  return (
    // <Navbar
    //   sticky="top"
    //   className="d-lg-none"
    //   collapseOnSelect
    //   expand="lg"
    //   bg="dark"
    //   variant="dark"
    // >
    //   <Container>
    //     <Navbar.Brand href="/">
    //       {" "}
    //       <Image src={props.logo} />
    //     </Navbar.Brand>
    //     <Navbar.Toggle aria-controls="responsive-navbar-nav" />
    //     <Navbar.Collapse id="responsive-navbar-nav">
    //       <Nav className="me-auto">
    //         <Nav.Link href="/">Home</Nav.Link>
    //         <NavDropdown title="About" id="collasible-nav-dropdown">
    //           <NavDropdown.Item href="/about">
    //             Why choose Knots Design?
    //           </NavDropdown.Item>
    //           <NavDropdown.Item href="/career">Career</NavDropdown.Item>
    //           <NavDropdown.Item href="/client">Clients</NavDropdown.Item>
    //           <NavDropdown.Item href="/partner">Partner</NavDropdown.Item>
    //         </NavDropdown>
    //         <NavDropdown title="Service" id="collasible-nav-dropdown">
    //           <NavDropdown.Item href="/animation">Animation</NavDropdown.Item>
    //           <NavDropdown.Item href="/branding">Barnding</NavDropdown.Item>
    //           <NavDropdown.Item href="/logo">Logo</NavDropdown.Item>
    //           <NavDropdown.Item href="/ecommerce">E-Commerce</NavDropdown.Item>
    //           <NavDropdown.Item href="/custom-website">
    //             Custom Website
    //           </NavDropdown.Item>
    //           <NavDropdown.Item href="/wordpress">WordPress</NavDropdown.Item>
    //           <NavDropdown.Item href="/shopify">Shopify</NavDropdown.Item>
    //           <NavDropdown.Item href="/uiux">UiUx</NavDropdown.Item>
    //           <NavDropdown.Item href="/social-media-marketing">
    //             SMM
    //           </NavDropdown.Item>
    //           {/* <NavDropdown.Item>Logo</NavDropdown.Item> */}
    //           <NavDropdown.Item href="/seo">SEO</NavDropdown.Item>
    //           <NavDropdown.Item href="/local-seo">Local SEO</NavDropdown.Item>
    //           <NavDropdown.Item href="/content-writing">
    //             Content Writing
    //           </NavDropdown.Item>
    //           <NavDropdown.Item href="/hosting">Hosting</NavDropdown.Item>
    //         </NavDropdown>
    //       </Nav>
    //       <Nav>
    //         <Nav.Link href="/our-work">Our Work</Nav.Link>
    //         {/* <Nav.Link eventKey={2} href="#memes">
    //         Packages
    //       </Nav.Link> */}
    //         <Nav.Link eventKey={2} href="#memes">
    //           <a
    //             style={{ textDecoration: "none" }}
    //             href="https://www.knotsdigital.com/insights/"
    //             target="_blank"
    //           >
    //             Insights
    //           </a>
    //         </Nav.Link>
    //         <Nav.Link eventKey={2} href="#memes">
    //           <a
    //             style={{ textDecoration: "none" }}
    //             href="/contact"
    //             target="_blank"
    //           >
    //             Contact Us
    //           </a>
    //         </Nav.Link>
    //       </Nav>
    //     </Navbar.Collapse>
    //   </Container>
    // </Navbar>
    <Container fluid className="pt-2 pb-2 mbl-navbar sticky-top">
      <Row className="h-100">
        <Col className="w-50 m-auto" sm={4} lg={6} d-lg-none>
        <a href="/">
          <Image width={"100%"} src={props.logo} />
          </a>
        </Col>
        <Col className="w-50 text-right nav-col" sm={4}>
          <div
            className={navClicked ? "clicked bar-div" : "unclicked bar-div"}
            onClick={navBarClicked}
          >
            <span className="mbl-bar bar-1"></span>
            <span className="mbl-bar bar-2"></span>
            <span className="mbl-bar bar-3"></span>
          </div>
          {navClicked ? (
            <div
              className={
                navClicked
                  ? "full_page_mbl_nav display_nav_screen"
                  : "full_page_mbl_nav hide_nav_screen"
              }
              data-aos="fade-right"
            >
              <div className="mbl_nav_link">
                <a href="/">
                  <Image
                    className="mt-1 mb-1 p-3"
                    // width={"auto"}
                    src={props.logo}
                    width={"50%"}
                  />
                </a>
                <ul>
                  <li>
                    <span className=" text-white">
                      <a href="/">Home</a>
                    </span>
                  </li>
                  <li className="" onClick={showService}>
                    <span className=" text-white">
                      Services
                      <span className={serviceNav ? "notrotate" : "rotate"}>
                        <BsFillCaretDownFill />
                      </span>
                    </span>
                    {serviceNav ? (
                      <ul>
                        <li data-aos="fade-left" data-aos-duration="500">
                          <span>
                            <a href="/custom-website">Custom Website</a>
                          </span>
                        </li>
                        <li data-aos="fade-left" data-aos-duration="1000">
                          <span>
                            <a href="/wordpress">WordPress Website</a>
                          </span>
                        </li>
                        <li data-aos="fade-left" data-aos-duration="1500">
                          <span>
                            <a href="/ecommerce">E-commerce Website</a>
                          </span>
                        </li>
                        <li data-aos="fade-left" data-aos-duration="2000">
                          <span>
                            <a href="/shopify">Shopify Website</a>
                          </span>
                        </li>
                        <li data-aos="fade-left" data-aos-duration="3000">
                          <span>
                            <a href="/logo">Logo Design</a>
                          </span>
                        </li>
                        <li data-aos="fade-left" data-aos-duration="3000">
                          <span>
                            <a href="/animation">Animation</a>
                          </span>
                        </li>
                        <li data-aos="fade-left" data-aos-duration="3000">
                          <span>
                            <a href="/branding">Branding</a>
                          </span>
                        </li>
                        <li data-aos="fade-left">
                          <span>
                            <a href="/uiux">UI/UX</a>
                          </span>
                        </li>
                        <li data-aos="fade-left">
                          <span>
                            <a href="/social-media-marketing">SMM</a>
                          </span>
                        </li>
                        <li data-aos="fade-left">
                          <span>
                            <a href="/seo">SEO</a>
                          </span>
                        </li>
                        <li data-aos="fade-left">
                          <span>
                            <a href="/local-seo">Local SEO</a>
                          </span>
                        </li>
                        <li data-aos="fade-left">
                          <span>
                            <a href="/content-writing">Content Writing</a>
                          </span>
                        </li>
                        <li data-aos="fade-left">
                          <span>
                            <a href="/hosting">Hosting</a>
                          </span>
                        </li>
                      </ul>
                    ) : (
                      ""
                    )}
                  </li>
                  <li>
                    <span className=" text-white">
                      <a href="/our-work"> Work</a>
                    </span>
                  </li>
                  <li>
                    <span className=" text-white">
                      <a href="/packages">Packages</a>
                    </span>
                  </li>
                  <li>
                    <span className=" text-white">
                      <a href="/about">About</a>
                    </span>
                  </li>
                  <li>
                    <span className=" text-white">
                      <a href="/career">Careers</a>
                    </span>
                  </li>
                  <li>
                    <span className=" text-white">
                      <a href="/client">Clients</a>
                    </span>
                  </li>
                  <li>
                    <span className=" text-white">
                      <a href="/partner">Partners</a>
                    </span>
                  </li>
                  <li>
                    <span className=" text-white">
                      <a href="/contact">Contact</a>
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          ) : (
            ""
          )}
        </Col>
      </Row>
    </Container>
  );
}

export default MobileHeader;
