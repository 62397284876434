import React from "react";
import Slider from "react-slick";
import { Row, Col } from "react-bootstrap";
import Information from "../../Packages/PackageIncludes/Logo";
import "./lppricing.scss";
import { BsArrowDown, BsArrowUp } from "react-icons/bs";
import { useNavigate } from "react-router-dom";

function LpPricing() {
  const Data = Information.WebsitePackages;
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    vertical: true,
    verticalSwiping: true,
    autoplay: true,
    nextArrow: <BsArrowDown fill="#b5ed3f" />,
    prevArrow: <BsArrowUp fill="#b5ed3f" />,
  };
  const [count, setCount] = React.useState(0);
  const nav = useNavigate();
  const packageClicked = (d, c) => {
    // console.log(d  + " " + c);
    localStorage.setItem("Id", d);
    localStorage.setItem("Type", c);
    localStorage.setItem("countArray", 7);
    nav("/packages/" + c);
    console.log(d,c,count);
  };

  return (
    <div className="Lp_Pricing_section">
      <Slider {...settings}>
        {Data.concat(Information.EcommercePackages).map((d, i) => {
          return (
            <div className="packages_cards">
              <Row className="packages_cards_row">
                <Col sm={6}>
                  <h2>{d.data.Package.Cat}</h2>
                  <h3>${d.data.Package.price}</h3>
                  <p>{d.data.Package.description}</p>
                </Col>
                <Col sm={6}>
                  <p>
                    <strong>{d.data.Package.Cat} Includes:</strong>
                  </p>
                  <ul>
                    {d.data.Package.list.map((d) => {
                      return <li key={d}>{d}</li>;
                    })}
                  </ul>
                </Col>
                <button
                  className="btn_blue btn_packegs"
                  id="#"
                  onClick={() => packageClicked(d.data.id, d.type)}
                >
                  Veiw Details
                </button>
              </Row>
            </div>
          );
        })}
      </Slider>
    </div>
  );
}

export default LpPricing;
