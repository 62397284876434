import React from "react";
import { Link } from "react-router-dom";
import "./header.scss";
import { Row, Col, Image } from "react-bootstrap";
import Animation from "../assets/images/header/Icon/animation.png";
import Branding from "../assets/images/header/Icon/branding (6).png";
import Logo from "../assets/images/header/Icon/logo (7).png";
import uiux from "../assets/images/header/Icon/ux-interface.png";
import customwebsite from "../assets/images/header/Icon/web-design.png";
import WordPress from "../assets/images/header/Icon/wordpress (1).png";
import Shopify from "../assets/images/header/Icon/shopify.png";
import Ecommerce from "../assets/images/header/Icon/e-wallet.png";
import SMM from "../assets/images/header/Icon/social-media.png";
import SEO from "../assets/images/header/Icon/seo.png";
import SEO2 from "../assets/images/header/Icon/seo (2).webp";
import hosting from "../assets/images/header/Icon/web-hosting.webp";
import career from "../assets/images/header/Icon/career .png";
import client from "../assets/images/header/Icon/client.png";
import partners from "../assets/images/header/Icon/partners.png";
import whyus from "../assets/images/header/Icon/Why Us.png";
import contentwriting from "../assets/images/header/Icon/writing.webp";


export function HeaderDropdown(props) {
  return (
    <div
      className="dropdown_header about_drop"
      data-aos="fade-up"
      onMouseLeave={props.hideAboutDrop}
    >
      <ul>
        <li>
          <a href={"/about"}>
            <Image
              src={whyus}
              style={{ width: "30px", paddingRight: "10px" }}
            />
            Why choose Knots Design?
          </a>
        </li>
        <li>
          <a href={"/career"}>
            <Image
              src={career}
              style={{ width: "30px", paddingRight: "10px" }}
            />
            Career
          </a>
        </li>
        <li>
          <a href={"/client"}>
            <Image
              src={client}
              style={{ width: "30px", paddingRight: "10px" }}
            />
            Clients
          </a>
        </li>
        <li>
          <a href={"/partner"}>
            <Image
              src={partners}
              style={{ width: "30px", paddingRight: "10px" }}
            />
            Partner
          </a>
        </li>
        {/* <li>
                    <Link href={'/partner'}>
                        News
                    </Link>
                </li> */}
      </ul>
    </div>
  );
}

export function ServicesDropDown(props) {
  return (
    <div
      className="dropdown_header _services"
      data-aos="fade-up"
      onMouseLeave={props.hideserviceDrop}
    >
      <Row>
        <Col sm={3}>
          <h5>Design</h5>
          <ul>
            <li>
              <a href={"/animation"}>
                <Image
                  src={Animation}
                  style={{ width: "30px", paddingRight: "10px" }}
                />
                Animation
              </a>
            </li>
            <li>
              <a href={"/branding"}>
                <Image
                  src={Branding}
                  style={{ width: "30px", paddingRight: "10px" }}
                />
                Branding
              </a>
            </li>
            <li>
              <a href={"/logo"}>
                <Image
                  src={Logo}
                  style={{ width: "30px", paddingRight: "10px" }}
                />
                Logo
              </a>
            </li>
            <li>
              <a href={"/uiux"}>
                <Image
                  src={uiux}
                  style={{ width: "30px", paddingRight: "10px" }}
                />
                Ui/Ux
              </a>
            </li>
          </ul>
        </Col>
        <Col sm={3}>
          <h5>Development</h5>
          <ul>
          <li>
              <a href={"/custom-website"}>
                <Image
                  src={customwebsite}
                  style={{ width: "30px", paddingRight: "10px" }}
                />
                Custom Website
              </a>
            </li>
            <li>
              <a href={"/wordpress"}>
                <Image
                  src={WordPress}
                  style={{ width: "30px", paddingRight: "10px" }}
                />
                WordPress
              </a>
            </li>

            <li>
              <a href={"/shopify"}>
                <Image
                  src={Shopify}
                  style={{ width: "30px", paddingRight: "10px" }}
                />
                Shopify
              </a>
            </li>
            <li>
              <a href={"/ecommerce"}>
                <Image
                  src={Ecommerce}
                  style={{ width: "30px", paddingRight: "10px" }}
                />
                Ecommerce
              </a>
            </li>
          </ul>
        </Col>
        <Col sm={3}>
          <h5>Marketing</h5>
          <ul>
            <li>
              <a href={"/social-media-marketing"}>
                <Image
                  src={SMM}
                  style={{ width: "30px", paddingRight: "10px" }}
                />
                SMM
              </a>
            </li>
            <li>
              <a href={"/seo"}>
                <Image
                  src={SEO}
                  style={{ width: "30px", paddingRight: "10px" }}
                />
                SEO
              </a>
            </li>
            <li>
              <a href={"/local-seo"}>
                <Image
                  src={SEO2}
                  style={{ width: "30px", paddingRight: "10px" }}
                />
                Local SEO
              </a>
            </li>
            <li>
              <a href={"/content-writing"}>
                <Image
                  src={contentwriting}
                  style={{ width: "30px", paddingRight: "10px" }}
                />
                Content Writing
              </a>
            </li>
          </ul>
        </Col>
        <Col sm={3}>
          <h5>Host</h5>
          <ul>
            <li>
              <a href={"/Hosting"}>
                <Image
                  src={hosting}
                  style={{ width: "30px", paddingRight: "10px" }}
                />
                Hosting
              </a>
            </li>
          </ul>
        </Col>
      </Row>
    </div>
  );
}

