import React from "react";
import { Row, Col } from "react-bootstrap";
import CaseStudies from "../../common/CaseStudy/CaseStudy";
import { CaseStudyInormation } from "../../Packages/PackageIncludes/Logo";
import { useNavigate } from "react-router-dom";

function Work() {
  const nav = useNavigate();
  const DetailPage = (id) => {
    nav("/casestudies/lp/custom/" + id);
  };
  return (
    <Row className="customWebsiteOurWorkCard_Row">
      {CaseStudyInormation.sheet1.map((d, i) => {
          return (
            <Col lg={6} className="customWebsiteOurWorkCard_second_Col">
              <div onClick={() => DetailPage(d.id)} className="customWebsiteOurWorkCard_second_div">
                <img src={d.banner_img} />
                <h3>
                  <strong>{d.projecttitile}</strong>
                </h3>
                <p>
                  {d.banner_pera}
                </p>
              </div>
            </Col>
          );
        })}
    </Row>
  );
}

export default Work;
