import React from "react";
import './pagenotfound.scss'

function PageNotFound() {
  return (
    <div className="g-0 h-100 page_not_found">
      <video width={'100%'} muted autoPlay playsInline loop src={require("../assets/videos/All.mp4")} />
        <div className="over-lay">
            <div className="m-auto">
            <h1>Page Not Found</h1>
            </div>

        </div>
    </div>
  );
}

export default PageNotFound;
