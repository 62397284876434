import React, { useState } from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import "./header.scss";
import logo from "../assets/images/Logo.webp";
import logoblack from "../assets/images/Knot-Digital-Final-Logos2.webp";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { HeaderDropdown, ServicesDropDown } from "./HeaderDropdown";
import { BsChatRightText } from "react-icons/bs";
import MobileHeader from "./MobileHeader";

function Header() {
  const [sticky, setSticky] = React.useState("");
  const location = useLocation();
  const [showAbouDrop, SetShowAbouDrop] = useState(false);
  const [showServiceDrop, SetShowServiceDrop] = useState(false);
  const [scrollT, setscrollT] = useState(0);
  console.log(location.pathname);
  // on render, set listener
  React.useEffect(() => {
    console.log("hello");
    window.addEventListener("scroll", isSticky);
    return () => {
      window.removeEventListener("scroll", isSticky);
    };
  }, []);

  const isSticky = () => {
    /* Method that will fix header after a specific scrollable */
    const scrollTop = window.scrollY;
    const stickyClass = scrollTop > 100 ? "is-sticky lazey" : "";
    setSticky(stickyClass);
    setscrollT(scrollTop);
    console.log(scrollTop);
  };

  const showAboutDrop = () => {
    console.log("Ye ho");
    SetShowAbouDrop(true);
    hideserviceDrop();
  };

  const hideAboutDrop = () => {
    SetShowAbouDrop(false);
  };

  const showservicedrop = () => {
    console.log("Ye ho");
    SetShowServiceDrop(true);
    hideAboutDrop();
  };

  const hideserviceDrop = () => {
    SetShowServiceDrop(false);
  };

  const LeaveMouse = () => {
    hideserviceDrop();
    hideAboutDrop();
  };

  const EnterMouse = () => {};

  const classes = `header-section d-none d-xl-flex ${sticky}`;
  return location.pathname === "/brief" ||
    location.pathname === "/logo-brief" ||
    location.pathname === "/lp/custom-website" ? (
    ""
  ) : (
    <>
      <Container className="nav_section" onMouseLeave={LeaveMouse}>
        <Row className={classes}>
          <Col className="g-0" sm="4" style={{ position: "relative" }}>
            <ul>
              {/* <li>
                <a
                  className={
                    location.pathname === "/" || location.pathname === "/404" ? "text-white" : "text-black"
                  }
                  href="/"
                >
                  Home
                </a>
              </li> */}
              <li>
                <a
                  className={
                    location.pathname === "/" || location.pathname === "/404"
                      ? "text-white"
                      : "text-black"
                  }
                  href="/our-work"
                >
                  Work
                </a>
              </li>
              <li onMouseEnter={showAboutDrop}>
                <a
                  className={
                    location.pathname === "/" || location.pathname === "/404"
                      ? "text-white"
                      : "text-black"
                  }
                  href="#"
                >
                  About
                </a>
              </li>
              <li onMouseEnter={showservicedrop}>
                <a
                  className={
                    location.pathname === "/" || location.pathname === "/404"
                      ? "text-white"
                      : "text-black"
                  }
                  href="#"
                >
                  Service
                </a>
              </li>
            </ul>
            {showAbouDrop ? (
              <HeaderDropdown
                data-aos="fade-up"
                hideAboutDrop={hideAboutDrop}
              />
            ) : (
              ""
            )}
            {showServiceDrop ? (
              <ServicesDropDown
                data-aos="fade-up"
                hideserviceDrop={hideserviceDrop}
              />
            ) : (
              ""
            )}
          </Col>
          <Col className="g-0 text-center" sm="4">
            <a href="/">
              {scrollT >= 100 ? (
                <Image src={logo} />
              ) : (
                <Image
                  src={
                    location.pathname === "/" || location.pathname === "/404"
                      ? logo
                      : logoblack
                  }
                />
              )}
            </a>
          </Col>
          <Col className="g-0" sm="4" style={{ position: "relative" }}>
            <ul>
              <li>
                <a
                  className={
                    location.pathname === "/" || location.pathname === "/404"
                      ? "text-white"
                      : "text-black"
                  }
                  disabled
                  href="#"
                  // target="_blank"
                >
                  Insights
                </a>
              </li>
              <li>
                <a
                  className={
                    location.pathname === "/" || location.pathname === "/404"
                      ? "text-white"
                      : "text-black"
                  }
                  href="/packages"
                >
                  Packages
                </a>
              </li>
              <li>
                <a
                  className={
                    location.pathname === "/" || location.pathname === "/404"
                      ? "text-white"
                      : "text-black"
                  }
                  href="/contact"
                >
                  Contact Us
                </a>
              </li>
            </ul>
          </Col>
          <Row>
            <div style={{ position: "relative" }}>
              <button className="btn_pink header_btn_conatct">
                <a href="/contact">
                  CONTACT US <BsChatRightText />
                </a>
              </button>
            </div>
          </Row>
        </Row>
      </Container>
      <MobileHeader logo={logo} />
    </>
  );
}

export default Header;
