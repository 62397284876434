import React from "react";
import "./process.scss";
import { Row, Col, Image } from "react-bootstrap";

function Process(props) {
  const [isOpen, setIsOpen] = React.useState(false);
  const [tab, setTabs] = React.useState();
  // Data

  const img = [
    {
      img: require("../../assets/images/About/awards-logo.webp"),
      title: "Ideate",
    },
    {
      img: require("../../assets/images/About/px-Color Fill 1.webp"),
      title: "Research",
    },
    {
      img: require("../../assets/images/About/awards-logo.webp"),
      title: "Strategize",
    },
    {
      img: require("../../assets/images/About/awards-logo.webp"),
      title: "Design",
    },
    {
      img: require("../../assets/images/About/awards-logo.webp"),
      title: "Revise",
    },
    {
      img: require("../../assets/images/About/awards-logo.webp"),
      title: "Deliver",
    },
  ];

  React.useEffect(() => {
    setTabs(data.rows[0].title);
  }, []);

  // const data = {
  //   title: "",
  //   rows: [
  //     {
  //       title: "Ideate",
  //       content: `When it comes to branding, then our experts brainstorm along with the team and come up with complete branding solutions for our clients. `,
  //     },
  //     {
  //       title: "Research",
  //       content:
  //         "The branding experts at Knots Digital stress a lot on research-based methodologies to make sure that all the branding activities are appropriate and aligned with the client's brief.",
  //     },
  //     {
  //       title: "Strategize",
  //       content: `Our team works diligently to craft an effective platform that drives customer engagement, streamlines commerce, and bolsters your brand's visibility.`,
  //     },
  //     {
  //       title: "Design",
  //       content: `Our team works diligently to craft an effective platform that drives customer engagement, streamlines commerce, and bolsters your brand's visibility.`,
  //     },
  //     {
  //       title: "Revise",
  //       content: `To ensure a successful business, cultivating brand loyalty is key. That's why we strive to provide the best customer experience. We focus on the notion of double-checking every detail in our projects prior to delivery so that customers keep coming back time and again.`,
  //     },
  //     {
  //       title: "Deliver",
  //       content: `It is our core objective to focus on timely delivery because we consider it an ethical duty to fulfill the requests of the clients in a timely manner. `,
  //     },
  //   ],
  // };

  // style

  // const Tabs = ["", "", ""];
  const ProcessClicked = (t) => {
    console.log(t);
    if (t !== null) {
      setIsOpen(true);
      setTabs(t);
    } else {
      // setIsOpen(false);
    }
  };
  const data = props.data1;
  console.log("DATA ONE" + data);
  return (
    <Row className="process_container">
      <Col className="m-auto" sm={12} lg={6}>
        <ul>
          {data.rows.map((d, i) => {
            return (
              <li>
                <h4
                  className={tab === d.title ? "active" : ""}
                  onClick={() => ProcessClicked(d.title)}
                >
                  <span>{i + 1}.</span>
                  {d.title}
                </h4>
                {tab === d.title ? (
                  <div data-aos="fade-down">
                    <p>{d.content}</p>
                  </div>
                ) : (
                  ""
                )}
              </li>
            );
          })}
        </ul>
      </Col>
      <Col sm={12} lg={6} className="text-center">
        {data.rows.map((d, i) => {
          return (
            <Image
              className={d.title === tab ? "d-block m-auto" : "d-none"}
              src={d.img}
              data-aos="fade-left"
            />
          );
        })}
      </Col>
    </Row>
  );
}

export default Process;
