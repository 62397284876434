import { Button } from "bootstrap";
import React from "react";
import { Col, Row } from "react-bootstrap";

function PersonalInformation(props) {
  return (
    <div className="personal_details">
      <h2>Your Personal <br/> Information</h2>
      <Row className="pb-2">
        <Col>
          <input
            name="name"
            type="text"
            placeholder="Mark Peterson"
            onChange={props.handlechange}
          />
        </Col>
        <Col>
          <input
            name="email"
            type="email"
            placeholder="yourname@email.com"
            onChange={props.handlechange}
          />
        </Col>
      </Row>
      <Row className="pb-2">
        <Col>
          <input
            name="contact"
            type="tel"
            placeholder="Phone Number"
            onChange={props.handlechange}
          />
        </Col>
        <Col>
          <input
            name="url"
            type="url"
            placeholder="Your Website"
            onChange={props.handlechange}
          />
        </Col>
      </Row>
      <Row className="pb-2">
        <Col>
          <input
            name="address"
            type="text"
            placeholder="Complete Address"
            onChange={props.handlechange}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <button type="_next" className="brief_btn text-black" onClick={props.next}>
            Next
          </button>
        </Col>
      </Row>
    </div>
  );
}

export default PersonalInformation;
